import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import Textbox from '../layout/Textbox';
export default function ConsultaDashboardAuditoria(): React.JSX.Element {
  const router = useNavigate()
  const [FilterNombreEquipoTrabajo, setFilterNombreEquipoTrabajo] = React.useState('')
  const [FilterEmpresa, setFilterEmpresa] = React.useState('')
  const [ListaConsulta, setListaConsulta] = React.useState([])
  React.useEffect(() => {
    
  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Dashboard Auditoría"} homePath={"Home"} currentPath={"Dashboard Auditoría"} >
    </ContentHeader>
    <ContentBody title={"Dashboard Auditoría"}>
      <div className='row'>
        <div className='col-lg-12'>
            <iframe width="100%" height="600" src="https://lookerstudio.google.com/embed/reporting/f5b74c40-0e87-42e8-8ccd-36b3907054ca/page/kDkDE" frameBorder="0" style={{border: 0}} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        </div>
      </div>
    </ContentBody>
  </div>
  )
}