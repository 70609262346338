'use client'

  import React, { useMemo } from 'react';
  import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
  import { useNavigate } from "react-router-dom";
  import { Box, IconButton } from '@mui/material';
  import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Email as EmailIcon,
  } from '@mui/icons-material';
  import ContentHeader from '../layout/ContentHeader';
  import ContentBody from '../layout/ContentBody';
  import * as Controller from  "../../../controllers/registroalerta-controller";
  import * as Repository from  "../../../repositories/registroalerta-repository";
  import SelectSimple from '../layout/SelectSimple';
  import DatePicker, { registerLocale } from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import es from "date-fns/locale/es"; // the locale you want
  registerLocale("es", es); // register it with the name you want

export default function ConsultaRegistroAlerta(): React.JSX.Element { 
  const router = useNavigate()
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [listaComboUsuario, setListaComboUsuario] = React.useState([])
  const [listaComboCadena, setListaComboCadena] = React.useState([])
  const [listaComboTienda, setListaComboTienda] = React.useState([])
  const [filterCodeUsuario, setFilterCodeUsuario] = React.useState('')
  const [filterCodeCadena, setFilterCodeCadena] = React.useState('')
  const [filterCodeTienda, setFilterCodeTienda] = React.useState('')
  const [filterFechaDesde, setFilterFechaDesde] = React.useState ( new Date)
  const [filterFechaHasta, setFilterFechaHasta] = React.useState ( new Date)
  {/*PENDIENTE EL FECHAHASTA  (FIX) */}


  React.useEffect(() => {
    Controller.getListaComboTienda(setListaComboTienda)
    Controller.getListaComboCadena(setListaComboCadena)
    Controller.getListaComboUsuario(setListaComboUsuario)
    Controller.doConsultar(setListaConsulta, filterCodeUsuario, filterCodeCadena, filterCodeTienda, filterFechaDesde, filterFechaHasta)
  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Consulta Registro Alerta"} homePath={"Home"} currentPath={"Mantenimiento Registro Alerta"} >
    </ContentHeader>
    <ContentBody title={"Consulta Registro Alerta"}>

    <div className='row'>
        <div className='col-lg-2 '>
          <label>Horario Desde</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
          <DatePicker locale="es" className='form-control-text' selected={filterFechaDesde} onChange={(date: any) => setFilterFechaDesde(date)} dateFormat={"dd/MM/yyyy"} />
          </div>
        </div>
        <div className='col-lg-2'>
          <label>Horario Hasta</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
        <DatePicker locale="es" className='form-control-text' selected={filterFechaHasta} onChange={(date: any) => setFilterFechaHasta(date)} dateFormat={"dd/MM/yyyy"} />
        </div>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-lg-2'>
          <label>Usuario</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <SelectSimple 
              className={"form-control-text"} 
              lista={listaComboUsuario} 
              value={filterCodeUsuario} 
              setValue={setFilterCodeUsuario}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Cadena</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <SelectSimple 
              className={"form-control-text"} 
              lista={listaComboCadena} 
              value={filterCodeCadena} 
              setValue={setFilterCodeCadena}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Tienda</label>
        </div >
        <div className='col-lg-4 '>
          <div className='form-group'>
            <SelectSimple 
              className={"form-control"} 
              lista={listaComboTienda} 
              value={filterCodeTienda} 
              setValue={setFilterCodeTienda}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-gl-6'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, filterCodeUsuario, filterCodeCadena, filterCodeTienda, filterFechaDesde,  filterFechaHasta)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(filterCodeUsuario, filterCodeCadena, filterCodeTienda, filterFechaDesde, filterFechaHasta)}  >Exportar</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 100 }}>
              <div className='row'>
              <div className="col-lg-4">
                <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                  <EditIcon titleAccess="Editar" />
                </IconButton>
                </div>
                <div className="col-lg-4">
                
                <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                  <DeleteIcon titleAccess="Eliminar" />
                </IconButton>
                </div>
              </div>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}