import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Pregunta = {
 

};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Pregunta>[]>(
    () => [
      {
        accessorKey: 'NombreEncuesta',
        header: 'Encuesta',
        size: 400,
      },
    ],
    [],
  );
  return columns
}
type EncuestaDetalle = {
  id: string;
  DesEncuesta: string,
  CodeRespuesta: string,
  DesOpcionRespuesta: string,
};

type EncuestaFoto = {
  id: string,
  DesFoto: string
};

export function GetMColumns() {
  const columns = useMemo<MRT_ColumnDef<EncuestaDetalle>[]>(
    () => [
      {
        accessorKey: 'Orden',
        header: 'Orden',
        Cell: ({ row }) => row.index + 1, // Asigna el orden según la posición en la lista
      },
      {
        accessorKey: 'DesEncuesta',
        header: 'Descripcion Encuesta',
        size: 300,
      },
      {
        accessorKey: 'DesOpcionRespuesta',
        header: 'Opcion Respuesta ',
        size: 100,
      },
    ],
    [],
  );
  ;
  return columns
}

export function GetMColumnsFoto() {
  const columns = useMemo<MRT_ColumnDef<EncuestaFoto>[]>(
    () => [
      {
        accessorKey: 'Orden',
        header: 'Orden',
        Cell: ({ row }) => row.index + 1, // Asigna el orden según la posición en la lista
      },
      {
        accessorKey: 'DesFoto',
        header: 'Descripcion Foto',
        size: 300,
      },
    ],
    [],
  );
  ;
  return columns
}


export async function getData(FilterDesEncuesta: string) {

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_ENCUESTA + "/" + FilterDesEncuesta, "")
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return []
  }
}
export async function getByCode(code: string) {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_ENCUESTABYCODE + "/" + code, "")
  return dataResponse.Data;
}
export async function save(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_ENCUESTA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return []
  }
}
export async function update(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.UPDATE_ENCUESTA, "", objSave)
  if (dataResponse != null) {
    return dataResponse;
  } else {
    return null
  }
}
export async function inactiva(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INACTIVO_ENCUESTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}
export async function activa(objSave: any) {
  var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.ACTIVO_ENCUESTA, "", objSave)
  if (dataResponse.Data != null) {
    return dataResponse.Data;
  } else {
    return null
  }
}

export async function doExport(FilterDesEncuesta: string) {
  var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_ENCUESTA_EXCEL + "/" + FilterDesEncuesta, 'DescargarExcelEncuesta.xlsx')
}

export async function getListaComboPregunta(){
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_PREGUNTA, "")
  if(dataResponse.Data != null){
    return dataResponse.Data;
  }else {
    return null
  }
}
