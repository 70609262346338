import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';
type auditoria = {
  FechaHoraRegistro: Date
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<auditoria>[]>(
    () => [
    
      
      {
        accessorKey: 'NombreTienda',
        header: 'Tienda',
        size: 100,
      },
      {
        id: 'FechaHoraRegistro',
        header: 'Fecha hora registro',
        size: 100,
        accessorFn: (row) => {
          return moment(row.FechaHoraRegistro).format("DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        accessorKey: 'Nota',
        header: 'Nota',
        size: 100,
      },
      {
        accessorKey: 'NombreCompleto',
        header: 'Usuario registra',
        size: 100,
      },
    ],
    [],
  );
  ;
  return columns
}
export async function getData(FilterCadena:string, FilterTienda: string, FilterFechaDesde:Date, FilterFechaHasta: Date) {
  
    FilterCadena = (FilterCadena == "" || FilterCadena == "0")? "-": FilterCadena
    FilterTienda = (FilterTienda == "" || FilterTienda == "0")? "-": FilterTienda

    var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") //dd-MM-yyyy
    var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
   
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_AUDITORIA  + "/" +  FilterCadena + "/" + FilterTienda + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return []
    }
  }

  export async function getListaAuditoriaDetalleByCode(code: string) {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_AUDITORIA_DETALLE + "/" + code , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }

  export async function getListaAuditoriaDetalleFotoByCode(code: string) {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_AUDITORIA_DETALLE_FOTO + "/" + code , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }

  export async function getListaComboTienda() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_TIENDA + "/-" , "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }
  
  
   export async function getListaComboCadena() {
    var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_COMBO_CADENA, "")
    if (dataResponse.Data != null) {
      return dataResponse.Data;
    } else {
      return null
    }
  }
  
  export async function doExport(FilterCadena:string, FilterTienda: string, FilterFechaDesde:Date, FilterFechaHasta: Date) {
      FilterCadena = (FilterCadena == "" || FilterCadena == "0")? "-": FilterCadena
      FilterTienda = (FilterTienda == "" || FilterTienda == "0")? "-": FilterTienda
  
      var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") //dd-MM-yyyy
      var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
     
      var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_AUDITORIA_EXCEL  + "/" +  FilterCadena + "/" + FilterTienda + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta , 'DescargarExcelAuditoria.xlsx')
      
    }

    export async function save(objSave: any) {
      var dataResponse = await ClientAPI.RequestPost(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.INSERTAR_REGISTRO_INCIDENCIA, "", objSave)
      if (dataResponse != null) {
        return dataResponse;
      } else {
        return null
      }
    }