import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/auditoria-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import moment from 'moment';

export default function DetalleAuditoria() {
  const { code } = useParams();
  const router = useNavigate();
  const [auditoria, setAuditoria] = React.useState<any>({});
  const [ListaDetalleAuditoria, setListaDetalleAuditoria] = React.useState([]);

  React.useEffect(() => {
    if (code != "") {

      Controller.getListaAuditoriaDetalleByCode(code, setAuditoria, setListaDetalleAuditoria);
    }
  }, []);


  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Detalle auditoría"}
        homePath={"Home"}
        currentPath={"Detalle auditoría"}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{"Detalle auditoría"}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>Tienda</label>
                        </div>
                        <div className="col-md-8">
                          {auditoria.NombreTienda}
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>Fecha hora registro</label>
                        </div>
                        <div className="col-md-8">
                          {moment(auditoria.FechaHoraRegistro).format("DD/MM/YYYY HH:mm:ss")}
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>Nota</label>
                        </div>
                        <div className="col-md-8">
                          {auditoria.Nota}
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-md-4">
                          <label>Usuario registra</label>
                        </div>
                        <div className="col-md-8">
                          {auditoria.NombreCompleto}
                        </div>
                      </div>
                    </div>
                  </div>
                  {ListaDetalleAuditoria.length > 0 ?
                    <div>
                      {ListaDetalleAuditoria.map((item: any, index: number) => (
                        <div className="row" key={index} >
                          <div className="col-md-8">
                            <div className="card bg-light mb-3">
                              <div className="card-header"><b>{index + 1}</b> {item.DesEncuesta}</div>
                              <div className="card-body">
                                <p className="card-text">
                                  Respuesta: {item.DesRespuesta}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                      }
                    </div> :
                    <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
