

export function ValidateOpcionMenu(OPC: string, OpcionesMenu: any) {

    if (OpcionesMenu.length >0){
        console.log(OpcionesMenu);
        const existe = OpcionesMenu.some((item: any) => item.CodigoOpcionMenu === OPC);

        console.log("Existe: " + existe);

        return existe;
    }else{
        return false;
    }
}