import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/auditoria-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import TextArea from "../layout/TextArea";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you 

export default function IncidenciaAuditoria() {
  const { code } = useParams();
  const router = useNavigate();

  const [codeAuditoria, setCodeAuditoria] = React.useState("");
  const [fechaProgramado, setFechaProgramado] = React.useState(new Date());
  const [descripcion, setDescripcion] = React.useState("");
  const [valDescripcion, setValDescripcion] = React.useState(false);

  const [isSubmit, setIsSubmit] = React.useState(false);

  React.useEffect(() => {
    if (code != "") {
      setCodeAuditoria(code!)
    }
  }, []);

  function validarCampos() {
    var Result = true;
    setIsSubmit(true);

    if (descripcion == "" || descripcion == null) {
      Result = false;
      setValDescripcion(false);
    } else {
      setValDescripcion(true);
    }

    if (Result) {
      Controller.save(router, codeAuditoria, descripcion, fechaProgramado);
    } else {
      Controller.Mensaje();
    }

  }

  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Registro incidencia"}
        homePath={"Home"}
        currentPath={"Registro incidencia"}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{"Registro incidencia"}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-2">
                      <label>Fecha Programada</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control-text"
                          selected={fechaProgramado}
                          onChange={(date: any) => setFechaProgramado(date)}
                          dateFormat={"dd/MM/yyyy"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Descripción incidencia</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextArea
                          value={descripcion}
                          valueError={valDescripcion}
                          setValue={setDescripcion}
                          setValueError={setValDescripcion}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
