export const CODE_SERVER_RETURN = {
    OK: 0,
    ERROR: 1,
    VAL: 2,
};

export const PERFIL_USUARIO = {
    SUPERADMINISTRADOR: 1,
    ADMINISTRADOR: 2,
    SUPERVISOR: 3,
    MERCADERISTA: 4,
    VENDEDOR: 5
}

export const UPLOAD_FILESCADENAMASTER = {
    USUARIO_FOTO_CADENAMASTER: 1
};


export const UPLOAD_FILESEMPRESA = {
    USUARIO_FOTO_EMPRESA: 2
};


export const UPLOAD_FILEPRODUCTO = {
    USUARIO_FOTO_FOTOPRODUCTO: 3
};

export const UPLOAD_FILES = {
    USUARIO_FOTO_AVATAR: 4
};

export const UPLOAD_FILE_DOCUMENTO = {
    USUARIO_ADJUNTAR_DOCUMENTO: 5
};

export const UPLOAD_FILESCONTRATO = {
    USUARIO_ADJUNTAR_CONTRATO: 6
};

export const UPLOAD_FILESCS = {
    USUARIO_ADJUNTAR_CS: 7
};

export const UPLOAD_FILESCONSTANCIA = {
    USUARIO_ADJUNTAR_CONSTANCIA: 8

};

export const UPLOAD_FILESDNI = {
    USUARIO_ADJUNTAR_DNI: 9
};

export const UPLOAD_FILESALTASUNAT = {
    USUARIO_ADJUNTAR_ALTASUNAT: 10
};

export const OPCIONES_MENU = {
    OPC_SACLIENTE: "OPC_SACLIENTE",
    OPC_SAUSUARIO: "OPC_SAUSUARIO",
    OPC_SACADENA: "OPC_SACADENA",
    OPC_SATIENDA: "OPC_SATIENDA",
    OPC_SADOCUMENTO: "OPC_SADOCUMENTO",
    OPC_USUARIO: "OPC_USUARIO",
    OPC_PRGUSU: "OPC_PRGUSU",
    OPC_CARPER: "OPC_CARPER",
    OPC_CANCLA: "OPC_CANCLA",
    OPC_TNDOPE: "OPC_TNDOPE",
    OPC_MARCA: "OPC_MARCA",
    OPC_PROCAT: "OPC_PROCAT",
    OPC_PROSCAT: "OPC_PROSCAT",
    OPC_PRODUCTO: "OPC_PRODUCTO",
    OPC_CHKINOUT: "OPC_CHKINOUT",
    OPC_EQUTRA: "OPC_EQUTRA",
    OPC_INDSUP: "OPC_INDSUP",
    OPC_INDMCDO: "OPC_INDMCDO",
    OPC_INDPROM: "OPC_INDPROM",
    OPC_REGMETAVTA: "OPC_REGMETAVTA",
    OPC_INDVEND: "OPC_INDVEND",
    OPC_ANASTOCK: "OPC_ANASTOCK",
    OPC_DASHSTOCK: "OPC_DASHSTOCK",
    OPC_DASHAUDI: "OPC_DASHAUDI",
    OPC_AUDITO: "OPC_AUDITO",
    OPC_ANAPRECIO: "OPC_ANAPRECIO",
    OPC_ANAFRENTE: "OPC_ANAFRENTE",
    OPC_ANAOSA: "OPC_ANAOSA",
    OPC_ANAEXHI: "OPC_ANAEXHI",
    OPC_ANARPTFOTO: "OPC_ANARPTFOTO",
    OPC_GENDOC: "OPC_GENDOC",
    OPC_ENCUESTA: "OPC_ENCUESTA",
    OPC_PREGUNTA: "OPC_PREGUNTA",
    OPC_RESPUESTA: "OPC_RESPUESTA",
    OPC_INCID: "OPC_INCID",
    OPC_REGALERT: "OPC_REGALERT",
    OPC_INTSTOCK: "OPC_INTSTOCK"
}