import React, { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/empresa-controller";
import * as Repository from "../../../repositories/empresa-repository";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
export default function ConsultaEmpresa(): React.JSX.Element {
  const router = useNavigate();
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  const [ListaComboTipoDocumento, setListaComboTipoDocumento] = React.useState(
    []
  );
  const [FilterTipoDocumento, setFilterTipoDocumento] = React.useState(0);
  const [FilterNumeroDocumentoIdentidad, setFilterNumeroDocumentoIdentidad] =
    React.useState("");
  const [FilterNombreEmpresa, setFilterNombreEmpresa] = React.useState("");
  React.useEffect(() => {
    Controller.getListaComboTipoDocumento(setListaComboTipoDocumento);
    Controller.doConsultar(
      setListaConsulta,
      FilterTipoDocumento,
      FilterNumeroDocumentoIdentidad,
      FilterNombreEmpresa
    );
  }, []);
  return (
    <div className="content-wrapper" suppressHydrationWarning>
      <ContentHeader
        title={"Consulta Empresa"}
        homePath={"Home"}
        currentPath={"Mantenimiento Empresa"}
      ></ContentHeader>
      <ContentBody title={"Consulta Empresa"}>
        <div className="row">
          <div className="col-lg-2 ">
            <label>Tipo Documento</label>
          </div>
          <div className="col-lg-4 ">
            <div className="form-group">
              <SelectSimple
                className="form-control-text"
                lista={ListaComboTipoDocumento}
                value={FilterTipoDocumento}
                setValue={setFilterTipoDocumento}
              >
                --TODOS--
              </SelectSimple>
            </div>
          </div>
          <div className="col-lg-2 ">
            <label>Nombre Empresa</label>
          </div>
          <div className="col-lg-4 ">
            <div className="form-group">
              <Textbox
                value={FilterNombreEmpresa}
                setValue={setFilterNombreEmpresa} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            <label>Numero Documento</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">

            <Textbox
                value={FilterNumeroDocumentoIdentidad}
                setValue={setFilterNumeroDocumentoIdentidad} />
              
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-md-12">
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={() => Controller.toNuevo(router)}
              >
                Nuevo
              </button>
              <button
                className="btn btn-info"
                onClick={() =>
                  Controller.doConsultar(
                    setListaConsulta,
                    FilterTipoDocumento,
                    FilterNumeroDocumentoIdentidad,
                    FilterNombreEmpresa
                  )
                }
              >
                Consultar
              </button>
              <button
                className="btn btn-success"
                onClick={() =>
                  Controller.doExportar(
                    FilterNombreEmpresa,
                    FilterTipoDocumento,
                    FilterNumeroDocumentoIdentidad
                  )
                }
              >
                Exportar
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaterialReactTable
              columns={Repository.GetColumns()}
              data={ListaConsulta}
              enableRowActions
              renderRowActions={({ row, table }) => (
                <div>
                  <IconButton
                    onClick={() => Controller.toEditar(router, row.original)}
                  >
                    <EditIcon titleAccess="Editar" />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      Controller.toQuestionInactivaActiva(
                        row.original,
                        setListaConsulta
                      )
                    }
                  >
                    <DeleteIcon titleAccess="Eliminar" />
                  </IconButton>
                </div>
              )}
            />
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
