import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import Textbox from "../layout/Textbox";
import * as Controller from "../../../controllers/generardocumentos-controller";
import * as Repository from "../../../repositories/generardocumentos-repository";
import Label from "../layout/Label";
import Select from "react-select";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import MaterialReactTable from "material-react-table";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es);

export default function NuevoGenerarDocumento() {
  const { code } = useParams();
  const Router = useNavigate();
  const [ListaDocumentos, setListaDocumentos] = React.useState([]);
  const [ListaComboCadenaMaster, setListaComboCadenaMaster] = React.useState(
    []
  );
  const [ListaComboUsuario, setListaComboUsuario] = React.useState([]);
  const [ListaComboTienda, setListaComboTienda] = React.useState([]);
  const [tituloVentana, setTituloVentana] = React.useState(
    "Nuevo Documento Generado"
  );
  const [codeDocumentoGenerado, setCodeDocumentoGenerado] = React.useState("");
  const [codeCadenaMaster, setCodeCadenaMaster] = React.useState("");
  const [codeUsuario, setCodeUsuario] = React.useState("");
  const [codeTienda, setCodeTienda] = React.useState("");
  const [periodo, setPeriodo] = React.useState(new Date());
  const [valperiodo, setValPeriodo] = React.useState(false);
  const [valcodeUsuario, setValCodeUsuario] = React.useState(false);
  const [valcodeCadenaMaster, setValCodeCadenaMaster] = React.useState(false);
  const [valcodeTienda, setValCodeTienda] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [horarioTienda, setHorarioTienda] = React.useState("");
  const [ListaHorarioTienda, setListaHorarioTienda] = React.useState([]);

  const onchangeCadenaMaster = (e: any) => {
    setCodeCadenaMaster(e.target.value);
    Controller.getListaComboTienda(e.target.value, setListaComboTienda);
    Controller.geListaCadenaDocumentoDetalleByCodeCadena(
      e.target.value,
      setListaDocumentos
    );
  };

  React.useEffect(() => {
    Controller.getListaComboUsuario(setListaComboUsuario);
    Controller.getListaComboCadenaMaster(setListaComboCadenaMaster);

    if (code != "nuevo") {
      setTituloVentana("Editar Documento");
      setCodeDocumentoGenerado(code!);

      Controller.getByCode(
        code,
        setPeriodo,
        setCodeCadenaMaster,
        setCodeUsuario,
        setCodeTienda,
        setListaDocumentos
      );
    }
  }, []);

  function validarCampos() {
    var Result = true;
    var messageVal = null;
    setIsSubmit(true);

    if (codeUsuario == "" || codeUsuario == "0" || codeUsuario == null) {
      Result = false;
      setValCodeUsuario(false);
    } else {
      setValCodeUsuario(true);
    }
    
    if (ListaHorarioTienda.length == 0){
      messageVal = "Debe agregar tiendas";
      Result = false;
    }

    if (
      codeCadenaMaster == "" ||
      codeCadenaMaster == "0" ||
      codeCadenaMaster == null
    ) {
      Result = false;
      setValCodeCadenaMaster(false);
    } else {
      setValCodeCadenaMaster(true);
    }

    if (Result) {
      Controller.save(
        Router,
        codeDocumentoGenerado,
        codeUsuario,
        periodo,
        ListaHorarioTienda,
        ListaDocumentos,
        codeCadenaMaster
      );
    } else {
      Controller.Mensaje(messageVal);
    }
  }

  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-2">
                      <Label required={true}>Periodo</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <DatePicker
                          locale="es"
                          className="form-control-text"
                          popperPlacement="top-end"
                          selected={periodo}
                          onChange={(date: any) => setPeriodo(date)}
                          dateFormat={"MM-yyyy"}
                          showMonthYearPicker
                          showFullMonthYearPicker
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Selecionar Personas</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Select
                          value={codeUsuario}
                          closeMenuOnSelect={false}
                          options={ListaComboUsuario}
                          isMulti
                          onChange={(e: any) => {
                            setCodeUsuario(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-2">
                      <Label required={true}>Cadena</Label>
                    </div>
                    <div className="col-lg-4">
                      <SelectSimple
                        value={codeCadenaMaster}
                        setValue={setCodeCadenaMaster}
                        valueError={valcodeCadenaMaster}
                        setValueError={setValCodeCadenaMaster}
                        isSubmit={isSubmit}
                        lista={ListaComboCadenaMaster}
                        onChangeSection={onchangeCadenaMaster}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Tiendas</Label>
                    </div>
                    <div className="col-lg-4">
                      <Select
                        value={codeTienda}
                        isClearable
                        closeMenuOnSelect={false}
                        options={ListaComboTienda}
                        onChange={(e: any) => {
                          setCodeTienda(e);
                        }}
                      />
                    </div>
                    <div className="col-lg-1">
                      <Label>Horario</Label>
                    </div>
                    <div className="col-lg-2">
                      <Textbox value={horarioTienda} setValue={setHorarioTienda}></Textbox>
                    </div>
                    <div className="col-lg-2">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMemberTienda(
                            codeTienda,
                            setCodeTienda,
                            ListaComboTienda,
                            horarioTienda,
                            setHorarioTienda,
                            ListaHorarioTienda,
                            setListaHorarioTienda
                          )
                        }
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <MaterialReactTable
                        columns={Repository.GetHTColumns()}
                        data={ListaHorarioTienda}
                        enableRowActions
                        enablePagination={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        renderRowActions={({ row, table }) => (
                          <div>
                            <IconButton
                              onClick={() =>
                                Controller.toQuestionEliminarDocumentos(
                                  row.original,
                                  ListaHorarioTienda,
                                  setListaHorarioTienda
                                )
                              }
                            >
                              <DeleteIcon titleAccess="Eliminar" />
                            </IconButton>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6">
                      <h4>Documentos a generar</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <MaterialReactTable
                        columns={Repository.GetTColumns()}
                        data={ListaDocumentos}
                        enableRowActions
                        enablePagination={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        renderRowActions={({ row, table }) => (
                          <div>
                            <IconButton
                              onClick={() =>
                                Controller.toQuestionEliminarDocumentos(
                                  row.original,
                                  ListaDocumentos,
                                  setListaDocumentos
                                )
                              }
                            >
                              <DeleteIcon titleAccess="Eliminar" />
                            </IconButton>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(Router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
