"use client";

import ContentHeader from "../layout/ContentHeader";

import * as Controller from "../../../controllers/pregunta-controller";
import React, { Dispatch, SetStateAction } from "react";
import SelectSimple from "../layout/SelectSimple";
import { useParams, useNavigate } from "react-router-dom";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import * as Repository from "../../../repositories/pregunta-repository";

import Label from "../layout/Label";

import TextArea from "../layout/TextArea";
import MaterialReactTable from "material-react-table";
import { IconButton } from "@mui/material";
export default function NuevoPregunta() {
  const [ListaComboTipoGrupo, setListaComboTipoGrupo] = React.useState([]);
  const [ListaComboTipoArea, setListaComboTipoArea] = React.useState([]);
  const [ListaComboPregunta, setListaComboPregunta] = React.useState([]);
  const [ListaPregunta, setListaPregunta] = React.useState([]);
  const { code } = useParams();
  const Router = useNavigate();

  const [codePregunta, setCodePregunta] = React.useState("");
  const [codeRespuesta, setCodeRespuesta] = React.useState("");
  const [desPregunta, setDesPregunta] = React.useState("");
  const [idTipoArea, setIdTipoArea] = React.useState(0);
  const [idTipoGrupo, setIdTipoGrupo] = React.useState(0);
  const [tituloVentana, setTituloVentana] = React.useState("Nueva Pregunta");
  const [tomarFoto, setTomarFoto] = React.useState(false);
  const [validTipoArea, setValIdTipoArea] = React.useState(false);
  const [validTipoGrupo, setValIdTipoGrupo] = React.useState(false);
  const [valdesPregunta, setValDesPregunta] = React.useState("");
  const [isSubmit, setIsSubmit] = React.useState(false);

  const onChangeTipoArea = (e: any) => {
    setIdTipoArea(e.target.value);
    if (e.target.value == 1) {
      //SUPERVISION
      Controller.getListaComboTipoGrupoSupervision(
        e.target.value,
        setListaComboTipoGrupo
      );
    } else {
      Controller.getListaComboTipoGrupoIncidencia(
        e.target.value,
        setListaComboTipoGrupo
      );
    }
  };

  React.useEffect(() => {
    Controller.getListaComboTipoArea(setListaComboTipoArea);
    Controller.getListaComboPregunta(setListaComboPregunta);
    if (code != "nuevo") {
      setTituloVentana("Editar Pregunta");
      setCodePregunta(code!);
      Controller.getByCode(
        code,
        setIdTipoGrupo,
        setIdTipoArea,
        setListaPregunta,
        setListaComboTipoGrupo
      );
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);

    if (idTipoArea == 0 || idTipoArea == 0 || idTipoArea == null) {
      Result = false;
      setValIdTipoArea(false);
    } else {
      setValIdTipoArea(true);
    }
    if (idTipoGrupo == 0 || idTipoGrupo == 0 || idTipoGrupo == null) {
      Result = false;
      setValIdTipoGrupo(false);
    } else {
      setValIdTipoGrupo(true);
    }

    if (Result) {
      Controller.save(
        Router,
        codePregunta,
        idTipoGrupo,
        idTipoArea,
        ListaPregunta
      );
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Tipo Area</Label>
                    </div>
                    <div className="col-lg-4">
                      <SelectSimple
                        value={idTipoArea}
                        setValue={setIdTipoArea}
                        valueError={validTipoArea}
                        setValueError={setValIdTipoArea}
                        isSubmit={isSubmit}
                        lista={ListaComboTipoArea}
                        onChangeSection={onChangeTipoArea}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label required={true}>Tipo Grupo</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={idTipoGrupo}
                          setValue={setIdTipoGrupo}
                          valueError={validTipoGrupo}
                          setValueError={setValIdTipoGrupo}
                          isSubmit={isSubmit}
                          lista={ListaComboTipoGrupo}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <label>Descripcion Pregunta</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextArea
                          value={desPregunta}
                          valueError={valdesPregunta}
                          setValue={setDesPregunta}
                          setValueError={setValDesPregunta}
                          isSubmit={isSubmit}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Opcion Respuesta</label>
                    </div>
                    <div className="col-lg-4">
                      <SelectSimple
                        value={codeRespuesta}
                        setValue={setCodeRespuesta}
                        lista={ListaComboPregunta}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                    <div className="col-lg-4">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMember(
                            codeRespuesta,
                            desPregunta,
                            tomarFoto,
                            ListaPregunta,
                            ListaComboPregunta,
                            setListaPregunta,
                            setCodeRespuesta,
                            setDesPregunta
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={tomarFoto}
                            onChange={(e) => {
                              setTomarFoto(e.currentTarget.checked);
                            }}
                          />{" "}
                          Foto
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="col-md-12">
                      <MaterialReactTable
                        columns={Repository.GetMColumns()}
                        data={ListaPregunta}
                        enableRowActions
                        enablePagination={false}
                        enableRowSelection
                        enableTopToolbar={false}
                        renderRowActions={({ row, table }) => (
                          <div>
                            <IconButton
                              onClick={() =>
                                Controller.toQuestionEliminarMiembro(
                                  row.original,
                                  ListaPregunta,
                                  setListaPregunta
                                )
                              }
                            >
                              <DeleteIcon titleAccess="Eliminar" />
                            </IconButton>
                          </div>
                        )}
                      />
                      <div className="row mt-5">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <button
                              className="btn btn-default"
                              onClick={() => Controller.toConsulta(Router)}
                            >
                              Cancelar
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => validarCampos()}
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
