"use client";

import ContentHeader from "../layout/ContentHeader";

import * as Controller from "../../../controllers/encuesta-controller";
import React, { Dispatch, SetStateAction } from "react";
import SelectSimple from "../layout/SelectSimple";
import { useParams, useNavigate } from "react-router-dom";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";
import * as Repository from "../../../repositories/encuesta-repository";

import Label from "../layout/Label";

import TextArea from "../layout/TextArea";
import MaterialReactTable from "material-react-table";
import { IconButton } from "@mui/material";
import Textbox from "../layout/Textbox";
export default function NuevoEncuesta() {
  const [ListaComboPregunta, setListaComboPregunta] = React.useState([]);
  const [ListaEncuestaDetalle, setListaEncuestaDetalle] = React.useState([]);
  const [ListaEncuestaFoto, setListaEncuestaFoto] = React.useState([]);
  const { code } = useParams();
  const Router = useNavigate();

  const [codeEncuesta, setCodeEncuesta] = React.useState("");
  const [codeRespuesta, setCodeRespuesta] = React.useState("");
  const [nombreEncuesta, setNombreEncuesta] = React.useState("");
  const [valDesEncuesta, setValDesEncuesta] = React.useState("");
  const [desPregunta, setDesPregunta] = React.useState("");
  const [tituloVentana, setTituloVentana] = React.useState("Nueva Encuesta");
  const [valdesPregunta, setValDesPregunta] = React.useState("");
  const [editingRowEncuesta, setEditingRowEncuesta] = React.useState<string>();
  const [editingRowEncuestaFoto, setEditingRowEncuestaFoto] = React.useState<string>();
  const [desFoto, setDesFoto] = React.useState("");
  const [isSubmit, setIsSubmit] = React.useState(false);


  React.useEffect(() => {
    Controller.getListaComboPregunta(setListaComboPregunta);
    if (code != "nuevo") {
      setTituloVentana("Editar Encuesta");
      setCodeEncuesta(code!);
      Controller.getByCode(
        code,
        setNombreEncuesta,
        setListaEncuestaDetalle,
        setListaEncuestaFoto
      );
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);

    if (Result) {
      Controller.save(
        Router,
        codeEncuesta,
        nombreEncuesta,
        ListaEncuestaDetalle,
        ListaEncuestaFoto
      );
    } else {
      Controller.Mensaje();
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre Encuesta</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreEncuesta}
                          setValue={setNombreEncuesta}
                          valueError={valDesEncuesta}
                          setValueError={setValDesEncuesta}
                          isSubmit={isSubmit}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h5>Preguntas encuesta</h5>
                      <hr />
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-lg-2">
                      <label>Descripcion Pregunta</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextArea
                          value={desPregunta}
                          valueError={valdesPregunta}
                          setValue={setDesPregunta}
                          setValueError={setValDesPregunta}
                          isSubmit={isSubmit}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Opcion Respuesta</label>
                    </div>
                    <div className="col-lg-4">
                      <SelectSimple
                        value={codeRespuesta}
                        setValue={setCodeRespuesta}
                        lista={ListaComboPregunta}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                    <div className="col-lg-4">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMember(
                            editingRowEncuesta,
                            codeRespuesta,
                            desPregunta,
                            ListaEncuestaDetalle,
                            ListaComboPregunta,
                            setListaEncuestaDetalle,
                            setCodeRespuesta,
                            setDesPregunta,
                            setEditingRowEncuesta
                          )
                        }
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <MaterialReactTable
                        columns={Repository.GetMColumns()}
                        data={ListaEncuestaDetalle}
                        enableRowActions
                        enablePagination={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        enableStickyHeader
                        muiTableContainerProps={{
                          sx: { maxHeight: '400px' },
                        }}
                        renderRowActions={({ row, table }) => (
                          <div style={{ width: 150 }}>
                            <div className='row'>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() => {
                                    setEditingRowEncuesta(row.original.id)
                                    setDesPregunta(row.original.DesEncuesta)
                                    setCodeRespuesta(row.original.CodeRespuesta)
                                  }}
                                >
                                  <EditIcon titleAccess="Editar" />
                                </IconButton>
                              </div>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() =>

                                    setListaEncuestaDetalle((prevData) => prevData.filter((item: any) => item.id !== row.original.id))

                                    /*Controller.toQuestionEliminarMiembro(
                                      row.original,
                                      ListaEncuestaDetalle,
                                      setListaEncuestaDetalle
                                    )*/
                                  }
                                >
                                  <DeleteIcon titleAccess="Eliminar" />
                                </IconButton>
                              </div>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() => {
                                    const index = row.index
                                    if (index === 0) return // No mover si es el primer elemento
                                    const newData = [...ListaEncuestaDetalle];
                                    [newData[index], newData[index - 1]] = [newData[index - 1], newData[index]];
                                    setListaEncuestaDetalle(newData);
                                  }}
                                >
                                  <ArrowUpwardIcon titleAccess="Subir" />
                                </IconButton>
                              </div>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() => {
                                    const index = row.index
                                    if (index === ListaEncuestaDetalle.length - 1) return; // No mover si es el último elemento
                                    const newData = [...ListaEncuestaDetalle];
                                    [newData[index], newData[index + 1]] = [newData[index + 1], newData[index]];
                                    setListaEncuestaDetalle(newData);
                                  }}
                                >
                                  <ArrowDownwardIcon titleAccess="Bajar" />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h5>Fotos encuesta</h5>
                      <hr />
                    </div>
                  </div>


                  <div className="row mt-1">
                    <div className="col-lg-2">
                      <label>Descripcion Foto</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextArea
                          value={desFoto}
                          setValue={setDesFoto}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMemberFoto(
                            editingRowEncuestaFoto,
                            desFoto,
                            setListaEncuestaFoto,
                            setDesFoto,
                            setEditingRowEncuestaFoto
                          )
                        }
                      >
                        Agregar
                      </button>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      <MaterialReactTable
                        columns={Repository.GetMColumnsFoto()}
                        data={ListaEncuestaFoto}
                        enableRowActions
                        enablePagination={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        enableStickyHeader
                        muiTableContainerProps={{
                          sx: { maxHeight: '400px' },
                        }}
                        renderRowActions={({ row, table }) => (
                          <div style={{ width: 150 }}>
                            <div className='row'>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() => {
                                    setEditingRowEncuestaFoto(row.original.id)
                                    setDesFoto(row.original.DesFoto)
                                  }}
                                >
                                  <EditIcon titleAccess="Editar" />
                                </IconButton>
                              </div>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() =>

                                    setListaEncuestaFoto((prevData) => prevData.filter((item: any) => item.id !== row.original.id))

                                    /*Controller.toQuestionEliminarMiembro(
                                      row.original,
                                      ListaEncuestaDetalle,
                                      setListaEncuestaDetalle
                                    )*/
                                  }
                                >
                                  <DeleteIcon titleAccess="Eliminar" />
                                </IconButton>
                              </div>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() => {
                                    const index = row.index
                                    if (index === 0) return // No mover si es el primer elemento
                                    const newData = [...ListaEncuestaFoto];
                                    [newData[index], newData[index - 1]] = [newData[index - 1], newData[index]];
                                    setListaEncuestaFoto(newData);
                                  }}
                                >
                                  <ArrowUpwardIcon titleAccess="Subir" />
                                </IconButton>
                              </div>
                              <div className="col-lg-3">
                                <IconButton
                                  onClick={() => {
                                    const index = row.index
                                    if (index === ListaEncuestaFoto.length - 1) return; // No mover si es el último elemento
                                    const newData = [...ListaEncuestaFoto];
                                    [newData[index], newData[index + 1]] = [newData[index + 1], newData[index]];
                                    setListaEncuestaFoto(newData);
                                  }}
                                >
                                  <ArrowDownwardIcon titleAccess="Bajar" />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>


                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(Router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
