import { constants } from "buffer";
import React from "react";
import { Link } from "react-router-dom";
import { PERFIL_USUARIO } from "../../utils/contants";

const Sidebar = ({ Usuario }: { Usuario: any }) => {
  var optionsConfiguracion;
  var optionsAnalisis;
  var optionsPersonal;
  var optionsDocumentos;
  var optionsSoporte;
  var optionsIntegracion;

  var optionsConfiguracionfilteredList;
  var optionsAnalisisfilteredList;
  var optionsPersonalfilteredList;
  var optionsDocumentosfilteredList;
  var optionsSoportefilteredList;
  var optionsIntegracionfilteredList;

  var PerfilUsuario = Usuario.Perfilusuario;
  var opcionesMenuList = Usuario.OpcionesMenu;


  if (PerfilUsuario === PERFIL_USUARIO.SUPERADMINISTRADOR) {
    optionsConfiguracion = [
      {
        code: "OPC_SACLIENTE",
        path: "/main/empresa/consulta",
        text: "Clientes",
      },
      {
        code: "OPC_SAUSUARIO",
        path: "/main/usuario/consulta",
        text: "Usuarios",
      },
      {
        code: "OPC_SACADENA",
        path: "/main/cadenamaster/consulta",
        text: "Cadena Catalogo",
      },
      {
        code: "OPC_SATIENDA",
        path: "/main/tiendamaster/consulta",
        text: "Tienda Catalogo",
      },
    ];

    optionsDocumentos = [
      {
        code: "OPC_SADOCUMENTO",
        path: "/main/documento/consulta",
        text: "Documentos",
      }
    ]

  } else if (PerfilUsuario === PERFIL_USUARIO.ADMINISTRADOR) {
    optionsConfiguracion = [
      {
        code: "OPC_USUARIO",
        path: "/main/usuario/consulta",
        text: "Usuario",
      },
      {
        code: "OPC_PRGUSU",
        path: "/main/programacionusuario/consulta",
        text: "Programacion Usuario",
      },
      {
        code: "OPC_CARPER",
        path: "/main/cargopersona/consulta",
        text: "Cargo Persona",
      },
      {
        code: "OPC_CANCLA",
        path: "/main/canbioclave/consulta",
        text: "Cambio Clave",
      },
      {
        code: "OPC_TNDOPE",
        path: "/main/tienda/consulta",
        text: "Tiendas a operar",
      },
      {
        code: "OPC_MARCA",
        path: "/main/marca/consulta",
        text: "Marcas",
      },
      /*{
        path: "/main/marcaexhibicion/consulta",
        text: "Marcas Exhibicion",
      },*/
      {
        code: "OPC_PROCAT",
        path: "/main/categoria/consulta",
        text: "Categorias",
      },
      {
        code: "OPC_PROSCAT",
        path: "/main/subcategoria/consulta",
        text: "Sub Categorias",
      },
      {
        code: "OPC_PRODUCTO",
        path: "/main/producto/consulta",
        text: "Productos",
      },
    ];

    optionsPersonal = [
      {
        code: "OPC_CHKINOUT",
        path: "/main/checkinouttienda/consulta",
        text: "Registro Llegada y Salida",
      },
      /*{
        path: "/main/indicadorinternogestion/consulta",
        text: "Gestión Interna",
      },*/
      {
        code: "OPC_EQUTRA",
        path: "/main/equipotrabajo/consulta",
        text: "Equipo de Trabajo",
      },
      {
        code: "OPC_INDSUP",
        path: "/main/indicadorsupervision/consulta",
        text: "Supervision",
      },
      {
        code: "OPC_INDMCDO",
        path: "/main/indicadormercaderismo/consulta",
        text: "Mercaderismo",
      },
      {
        code: "OPC_INDPROM",
        path: "/main/indicadorpromotoria/consulta",
        text: "Promotoria",
      },
      {
        code: "OPC_REGMETAVTA",
        path: "/main/registrometaventa/consulta",
        text: "Objetivo ventas",
      },
      {
        code: "OPC_INDVEND",
        path: "/main/indicadorvendedores/consulta",
        text: "Vendedores",
      },
    ];
    optionsAnalisis = [
      {
        code: "OPC_ANASTOCK",
        path: "/main/analisisstock/consulta",
        text: "Stock",
      },
      {
        code: "OPC_DASHSTOCK",
        path: "/main/dashboardstock/consulta",
        text: "Dashboard Stock",
      },
      {
        code: "OPC_DASHAUDI",
        path: "/main/dashboardauditoria/consulta",
        text: "Dashboard Auditoría",
      },
      {
        code: "OPC_AUDITO",
        path: "/main/auditoria/consulta",
        text: "Auditoría",
      },
      {
        code: "OPC_ANAPRECIO",
        path: "/main/analisisprecio/consulta",
        text: "Precio",
      },
      {
        code: "OPC_ANAFRENTE",
        path: "/main/analisisfrente/consulta",
        text: "Frente",
      },
      {
        code: "OPC_ANAOSA",
        path: "/main/analisisosa/consulta",
        text: "Osa",
      },
      {
        code: "OPC_ANAEXHI",
        path: "/main/analisisexhibidores/consulta",
        text: "Exhibidores",
      },
      {
        code: "OPC_ANARPTFOTO",
        path: "/main/analisisreportefotografico/consulta",
        text: "Reporte Fotografico",
      },
    ];

    optionsDocumentos = [
      {
        code: "OPC_GENDOC",
        path: "/main/generardocumentos/consulta",
        text: "Generar documentos",
      }

    ];

    optionsSoporte = [
      {
        code: "OPC_ENCUESTA",
        path: "/main/encuesta/consulta",
        text: "Encuesta",
      },
      {
        code: "OPC_PREGUNTA",
        path: "/main/pregunta/consulta",
        text: "Pregunta",
      },

      {
        code: "OPC_RESPUESTA",
        path: "/main/respuesta/consulta",
        text: "Respuesta",
      },
      {
        code: "OPC_INCID",
        path: "/main/incidencia/consulta",
        text: "Incidencia",
      },
      {
        code: "OPC_REGALERT",
        path: "/main/registroalerta/consulta",
        text: "Registro Alerta",
      },
    ];

    optionsIntegracion = [
      {
        code: "OPC_INTSTOCK",
        path: "/main/integracionstockb2b/consulta",
        text: "Stock B2B",
      },
    ]
  } else if (PerfilUsuario === PERFIL_USUARIO.SUPERVISOR) {
  } else if (PerfilUsuario === PERFIL_USUARIO.MERCADERISTA) {
  } else {
  }


  //aplicar los filtros

  if (optionsConfiguracion != null) {
    optionsConfiguracionfilteredList = optionsConfiguracion.filter(item1 =>
      opcionesMenuList.some((item2: any) => item2.CodigoOpcionMenu === item1.code)
    );
  }

  if (optionsAnalisis != null) {
    optionsAnalisisfilteredList = optionsAnalisis.filter(item1 =>
      opcionesMenuList.some((item2: any) => item2.CodigoOpcionMenu === item1.code)
    );
  }

  if (optionsPersonal != null) {
    optionsPersonalfilteredList = optionsPersonal.filter(item1 =>
      opcionesMenuList.some((item2: any) => item2.CodigoOpcionMenu === item1.code)
    );
  }

  if (optionsDocumentos != null) {
    optionsDocumentosfilteredList = optionsDocumentos.filter(item1 =>
      opcionesMenuList.some((item2: any) => item2.CodigoOpcionMenu === item1.code)
    );
  }

  if (optionsSoporte != null) {
    optionsSoportefilteredList = optionsSoporte.filter(item1 =>
      opcionesMenuList.some((item2: any) => item2.CodigoOpcionMenu === item1.code)
    );
  }

  if (optionsIntegracion != null) {
    optionsIntegracionfilteredList = optionsIntegracion.filter(item1 =>
      opcionesMenuList.some((item2: any) => item2.CodigoOpcionMenu === item1.code)
    );
  }

  /*
  var optionsindicadores = [
    {
      path: "/main/indicadorinternogestion/consulta",
      text: "Interno Gestion",
    },
    {
      path: "/main/indicadormercaderismo/consulta",
      text: "Mercaderismo",
    },
    {
      path: "/main/indicadorpromotoria/consulta",
      text: "Promotoria",
    },
    {
      path: "/main/indicadorsupervision/consulta",
      text: "Supervision",
    },
    {
      path: "/main/indicadorvendedores/consulta",
      text: "Vendedores",
    },
  ];

  var optionsAnalisis = [
    {
      path: "/main/analisisstock/consulta",
      text: "Stock",
    },
    {
      path: "/main/analisisprecio/consulta",
      text: "Precio",
    },
    {
      path: "/main/analisisfrente/consulta",
      text: "Frente",
    },
    {
      path: "/main/analisisosa/consulta",
      text: "Osa",
    },
    {
      path: "/main/analisisexhibidores/consulta",
      text: "Exhibidores",
    },
    {
      path: "/main/analisisreportefotografico/consulta",
      text: "Reporte Fotografico",
    },
  ];

  var optionsMantenimientos = [
    {
      path: "/main/marca/consulta",
      text: "Marca",
    },
    {
      path: "/main/categoria/consulta",
      text: "Categoria",
    },
    {
      path: "/main/subcategoria/consulta",
      text: "SubCategoria",
    },
    {
      path: "/main/cliente/consulta",
      text: "Cliente",
    },
    {
      path: "/main/asistencias/consulta",
      text: "Asistencia",
    },

    {
      path: "/main/checkinouttienda/consulta",
      text: "ChechInOutTienda",
    },
    {
      path: "/main/documento/consulta",
      text: "Documento",
    },
    {
      path: "/main/dashboardstock/consulta",
      text: "Dashboardstock",
    },
    {
      path: "/main/equipotrabajo/consulta",
      text: "Equipo Trabajo",
    },
    {
      path: "/main/incidencia/consulta",
      text: "Incidencia",
    },
    {
      path: "/main/marcarepresenta/consulta",
      text: "Marca Representa",
    },
    {
      path: "/main/pregunta/consulta",
      text: "Pregunta",
    },
    {
      path: "/main/registroalerta/consulta",
      text: "Registro Alerta",
    },
    {
      path: "/main/respuesta/consulta",
      text: "Respuesta",
    },
    {
      path: "/main/supervision/consulta",
      text: "Supervision",
    },
    {
      path: "/main/registrometaventa/consulta",
      text: "Registro Meta venta",
    },
  ];*/

  function handleArrow(e: any) {
    let arrowParent = (e.target as HTMLElement).parentElement!.parentElement;
    console.log(arrowParent);
    arrowParent!.classList.toggle("showMenu");
  }

  return (
    <div className="sidebar">
      <div className="logo-details">
        <i className="bx bxl-c-plus-plus"></i>
        <span className="logo_name">TRADE+</span>
      </div>
      <ul className="nav-links">
        {/*<li>
          <a href="#">
            <i className="bx bx-grid-alt"></i>
            <span className="link_name">Configuración</span>
          </a>
          <ul className="sub-menu blank">
            {optionsConfiguracion.map((item: any) => {
              return (
                <li>
                  <Link to={item.path}>{item.text}</Link>
                </li>
              );
            })}
          </ul>
          </li>*/}
        {optionsConfiguracionfilteredList != null && optionsConfiguracionfilteredList.length > 0 ? (
          <li key={100}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Configuración</span>
              </a>
              <i
                key={0}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Configuración
                </a>
              </li>
              {optionsConfiguracionfilteredList != null &&
                optionsConfiguracionfilteredList.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsPersonalfilteredList != null && optionsPersonalfilteredList.length > 0 ? (
          <li key={101}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Personal</span>
              </a>
              <i
                key={2}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Personal
                </a>
              </li>
              {optionsPersonalfilteredList != null &&
                optionsPersonalfilteredList.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsAnalisisfilteredList != null && optionsAnalisisfilteredList.length > 0 ? (
          <li key={102}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Análisis</span>
              </a>
              <i
                key={3}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Análisis
                </a>
              </li>
              {optionsAnalisisfilteredList != null &&
                optionsAnalisisfilteredList.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsDocumentosfilteredList != null && optionsDocumentosfilteredList.length > 0 ? (
          <li key={103}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Documentos</span>
              </a>
              <i
                key={4}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Documentos
                </a>
              </li>
              {optionsDocumentosfilteredList != null &&
                optionsDocumentosfilteredList.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsSoportefilteredList != null && optionsSoportefilteredList.length > 0 ? (
          <li key={103}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Soporte</span>
              </a>
              <i
                key={4}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Soporte
                </a>
              </li>
              {optionsSoportefilteredList != null &&
                optionsSoportefilteredList.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsIntegracionfilteredList != null && optionsIntegracionfilteredList.length > 0 ? (
          <li key={103}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Integración</span>
              </a>
              <i
                key={4}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Integración
                </a>
              </li>
              {optionsIntegracionfilteredList != null &&
                optionsIntegracionfilteredList.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {/*<li>
          <div className="iocn-link">
            <a href="#">
              <i className="bx bx-book-alt"></i>
              <span className="link_name">Posts</span>
            </a>
            <i className="bx bxs-chevron-down arrow"></i>
          </div>
          <ul className="sub-menu">
            <li>
              <a className="link_name" href="#">
                Posts
              </a>
            </li>
            <li>
              <a href="#">HTML & CSS</a>
            </li>
            <li>
              <a href="#">Javascript</a>
            </li>
            <li>
              <a href="#">PHP & MySQL</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-pie-chart-alt-2"></i>
            <span className="link_name">Analytics</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="#">
                Analytics
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-line-chart"></i>
            <span className="link_name">Chart</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="#">
                Chart
              </a>
            </li>
          </ul>
          </li>*/}
        <li>
          {/*<div className="profile-details">
            <div className="profile-content">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                alt="Account"
              />
            </div>
            <div className="name-job">
              <div className="profile_name">Prem Shahi</div>
              <div className="job">Web Designer</div>
            </div>
            <i className="bx bx-log-out"></i>
        </div>*/}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
