import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/tienda-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import {
    CODE_SERVER_RETURN,
 
  } from "../utils/contants";
export function toNuevo(router: NavigateFunction) {
    router("/main/tienda/mantenimiento/nuevo")
}
export function toConsulta(router: NavigateFunction) {
    router("/main/tienda/consulta")
}
export async function doConsultar(setListaConsulta: any, FilterCadenaMaster: string, FilterCodigoTienda: string, FilterNombreTienda: string) {
    var dataResponse = await Repository.getData(FilterCadenaMaster, FilterCodigoTienda, FilterNombreTienda)
    setListaConsulta(dataResponse)
}
export async function toEditar(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeTienda
    router("/main/tienda/mantenimiento/" + codeEditar)
}
export async function toQuestionInactivaActiva(row: any, setListaConsulta: any) {
    const MySwal = withReactContent(Swal)
    var question = row.Estado == 1 ? "¿Desea eliminar el registro?" : "¿Desea activar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then((result: any) => {
        if (result.isConfirmed) {
            toInactivaActiva(row, setListaConsulta)
        }
    })
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
    var codeEliminar = row.CodeTienda
    const objSave = {
        CodeTienda: codeEliminar
    }
    if (row.Estado == 1) {
        var dataResponse = await Repository.inactiva(objSave)
    } else if (row.Estado == 0) {
        var dataResponse = await Repository.activa(objSave)
    }
    doConsultar(setListaConsulta, "", "", "")
}
export async function getByCodeTienda(codetienda: string, setCodigoTienda: any, setDireccion: any, setUbigeo: any, setLatitud : any, setLongitud : any ) {
    if (codetienda != "-" && codetienda != "0") {
        var dataResponse = await Repository.getByCodeTienda(codetienda)
        setCodigoTienda(dataResponse.CodigoTienda)
        setDireccion(dataResponse.Direccion)
        setUbigeo(dataResponse.NombreUbigeo)
        setLatitud(dataResponse.Latitud)
        setLongitud(dataResponse.Longitud)
       
    }else{
        setCodigoTienda("")
        setDireccion("")
        setUbigeo("")
        setLatitud("")
        setLongitud("")
    }

}

export async function getByCode(code:  any, setCodeTiendaMaster: any, setCodeCadenaMaster: any, setCodigoTienda: any, setDireccion: any, setUbigeo: any, setHorarioDesde: any, setHorarioHasta: any, lunes:any, martes: any, miercoles: any,jueves:any,
    viernes:any, sabado:any, nombreComercial:any, apellidoComercial:any, telefonoComercial: any, correoElectronicoComercial:any, dniComercial:any, setObservacion : any, setNombreCadena: any, setNombreTienda: any, setLatitud : any, setLongitud : any) {
    var dataResponse = await Repository.getByCode(code)

    if (dataResponse != null) {
        setCodeTiendaMaster(dataResponse.CodeTiendaMaster)
        setCodeCadenaMaster(dataResponse.CodeCadenaMaster)
        setCodigoTienda(dataResponse.CodigoTienda)
        setDireccion(dataResponse.Direccion)
        setUbigeo(dataResponse.NombreUbigeo)
        setHorarioDesde(dataResponse.HorarioDesde)
        setHorarioHasta(dataResponse.HorarioHasta)
        lunes(dataResponse.Lunes)
        martes(dataResponse.Martes)
        miercoles(dataResponse.Miercoles)
        jueves(dataResponse.Jueves)
        viernes(dataResponse.Viernes)
        sabado(dataResponse.Sabado)
        nombreComercial(dataResponse.NombreComercial)
        apellidoComercial(dataResponse.ApellidoComercial)
        telefonoComercial(dataResponse.TelefonoComercial)
        correoElectronicoComercial(dataResponse.CorreoElectronicoComercial)
        dniComercial(dataResponse.DniComercial)
        setObservacion(dataResponse.Observacion)
        setNombreCadena(dataResponse.NombreCadena)
        setNombreTienda(dataResponse.NombreTienda)
        setLatitud(dataResponse.Latitud)
        setLongitud(dataResponse.Longitud)
    }
}

export async function save(router: NavigateFunction, codeTienda: string, codeTiendaMaster: string, codeCadenaMaster: string, horariodesde: string, horariohasta: string, lunes: boolean,  martes: boolean, miercoles:boolean, jueves:boolean, viernes:boolean, sabado:boolean, domingo:boolean,   nombreComercial:string, apellidoComercial:string, telefonoComercial:string, correoElectronicoComercial:string, dniComercial: string,   observacion: string) {
    const MySwal = withReactContent(Swal)

    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            console.log("Code a actualizar: " + codeTienda)
            if (codeTienda != null && codeTienda != "") {
                const objSave = {

                    CodeTienda: codeTienda,
                    CodeTiendaMaster: codeTiendaMaster,
                    CodeCadenaMaster: codeCadenaMaster,
                    HorarioDesde: horariodesde,
                    HorarioHasta: horariohasta,
                    Lunes: lunes,
                    Martes : martes ,
                    Miercoles : miercoles,
                    Jueves : jueves,
                    Viernes : viernes,
                    Sabado: sabado,
                    Domingo :domingo,
                     NombreComercial: nombreComercial,
                     ApellidoComercial : apellidoComercial,
                     TelefonoComercial : telefonoComercial,
                     CorreoElectronicoComercial :correoElectronicoComercial,
                     DniComercial : dniComercial,
                    Observacion: observacion
                }
                var dataResponse = await Repository.update(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });

                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                } else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                  
                }
            } else {
                const objSave = {
                  
                    CodeTiendaMaster: codeTiendaMaster,
                    CodeCadenaMaster: codeCadenaMaster,
                    HorarioDesde: horariodesde,
                    HorarioHasta: horariohasta,
                    Lunes: lunes,
                    Martes : martes ,
                    Miercoles : miercoles,
                    Jueves : jueves,
                    Viernes : viernes,
                    Sabado: sabado,
                    Domingo :domingo,
                     NombreComercial: nombreComercial,
                     ApellidoComercial : apellidoComercial,
                     TelefonoComercial : telefonoComercial,
                     CorreoElectronicoComercial :correoElectronicoComercial,
                     DniComercial : dniComercial,
                    Observacion: observacion
                }
                var dataResponse = await Repository.save(objSave)
                if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "warning",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });

                } else if(dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                    MySwal.fire({
                        title: <p>{dataResponse.Message}</p>,
                        icon: "error",
                        showConfirmButton: true,
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        
                    });
                }else {
                    toast.success('Guardado satisfactoriamente!')
                    toConsulta(router)
                }
            }
        }
    })
}
export async function Mensaje(messageAlert: string) {
    const MySwal = withReactContent(Swal)
    var message = messageAlert || "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}
export async function getListaComboCadenaMaster(setListaComboCadenaMaster: any) {
    var dataResponse = await Repository.getListaComboCadenaMaster()
    setListaComboCadenaMaster(dataResponse)
}

export async function getListaTiendaMaster(codeMaster: string, setListaComboTiendaMaster: any) {
    if (codeMaster != "-" && codeMaster != "0") {
        var dataResponse = await Repository.getListaTiendaMaster(codeMaster)
        setListaComboTiendaMaster(dataResponse)
    } else {
        setListaComboTiendaMaster([])
    }
}

export async function doExportar(FilterNombreCadenaMaster: string, FilterCodigoTienda : string, FilterNombreTienda : string ) {
    Repository.doExport(FilterNombreCadenaMaster, FilterCodigoTienda, FilterNombreTienda)
}