import React, { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/cargopersona-controller";
import * as Repository from "../../../repositories/cargopersona-repository";
export default function ConsultaCargoPersona(): React.JSX.Element {
  const router = useNavigate();
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  const [FilterNombreCargoPersona, setFilterNombreCargoPersona] =
    React.useState("");
  React.useEffect(() => {
    Controller.doConsultar(setListaConsulta, FilterNombreCargoPersona);
  }, []);
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Consulta Cargo Persona"}
        homePath={"Home"}
        currentPath={"Mantenimiento Cargo Persona"}
      ></ContentHeader>
      <ContentBody title={"Consulta Cargo Persona"}>
        <div className="row">
          <div className="col-lg-2 ">
            <label>Cargo Persona</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control-text"
                value={FilterNombreCargoPersona}
                onChange={(e) => {
                  setFilterNombreCargoPersona(e.currentTarget.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-lg-6">
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={() => Controller.toNuevo(router)}
              >
                Nuevo
              </button>
              <button
                className="btn btn-info"
                onClick={() =>
                  Controller.doConsultar(
                    setListaConsulta,
                    FilterNombreCargoPersona
                  )
                }
              >
                Consultar
              </button>
              <button
                className="btn btn-success"
                onClick={() => Controller.doExportar(FilterNombreCargoPersona)}
              >
                Exportar
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaterialReactTable
              columns={Repository.GetColumns()}
              data={ListaConsulta}
              enableRowActions
              renderRowActions={({ row, table }) => (
                <div style={{ width: 100 }}>
                <div className="row">
                <div className="col-lg-4">
                  <IconButton
                    onClick={() => Controller.toEditar(router, row.original)}
                  >
                    <EditIcon titleAccess="Editar" />
                  </IconButton>
                  </div>
                  <div className="col-lg-4">
                  <IconButton
                    onClick={() =>
                      Controller.toQuestionInactivaActiva(
                        row.original,
                        setListaConsulta
                      )
                    }
                  >
                    <DeleteIcon titleAccess="Eliminar" />
                  </IconButton>
                </div>
                </div>
                </div>
              )}
            />
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
