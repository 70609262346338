import React, { useMemo, useContext } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, IconButton } from '@mui/material';
import {
    Edit as EditIcon,
    PhotoCamera as PhotoCameraIcon,
    ViewHeadline as ViewIcon,
    EditNote as EditNoteIcon
} from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/auditoria-controller";
import * as Repository from "../../../repositories/auditoria-repository";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectSimple from '../layout/SelectSimple';
import es from "date-fns/locale/es"; // the locale you want
import { UserContext } from "../../main/main-screen";
import { OPCIONES_MENU } from "../../../utils/contants";
import * as Validation from "../../../utils/validation";

registerLocale("es", es); // register it with the name you want  
export default function ConsultaAuditoria(): React.JSX.Element {
    const router = useNavigate()
    const { Perfilusuario, OpcionesMenu } = useContext(UserContext);
    const [ListaConsulta, setListaConsulta] = React.useState([])
    const [ListaComboCadena, setListaComboCadena] = React.useState([])
    const [ListaComboTienda, setListaComboTienda] = React.useState([])
    const [FilterCadena, setFilterCadena] = React.useState('')
    const [FilterTienda, setFilterTienda] = React.useState('')
    const [FilterCodeMarca, setFilterCodeMarca] = React.useState('')
    const [FilterCodeCategoria, setFilterCategoria] = React.useState('')
    const [FilterCodeSubCategoria, setFilterSubCategoria] = React.useState('')
    const [FilterFechaDesde, setFilterFechaInicio] = React.useState(new Date)
    const [FilterFechaHasta, setFilterFechaHasta] = React.useState(new Date)

    React.useEffect(() => {
        if (!Validation.ValidateOpcionMenu(OPCIONES_MENU.OPC_AUDITO, OpcionesMenu)) {
            router("/main");
        }

        Controller.getListaComboTienda(setListaComboTienda)
        Controller.getListaComboCadena(setListaComboCadena)
        Controller.doConsultar(setListaConsulta, FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)

    }, [])
    return (<div className="content-wrapper">
        <ContentHeader title={"Consulta auditoría"} homePath={"Home"} currentPath={"Consulta auditoría"} >
        </ContentHeader>
        <ContentBody title={"Consulta auditoría"}>
            <div className="card-body">
                <div className='row'>
                    <div className='col-lg-2'>
                        <label>Fecha Desde</label>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='form-group'>
                            <DatePicker locale="es" className='form-control-text' selected={FilterFechaDesde} onChange={(date: any) => setFilterFechaInicio(date)} dateFormat={"dd/MM/yyyy"} />
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <label>Fecha Hasta</label>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='form-group'>
                            <DatePicker locale="es" className='form-control-text' selected={FilterFechaHasta} onChange={(date: any) => setFilterFechaHasta(date)} dateFormat={"dd/MM/yyyy"} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-2'>
                        <label>Cadena</label>
                    </div>
                    <div className='col-lg-4 '>
                        <SelectSimple
                            className={"form-control-text"}
                            lista={ListaComboCadena}
                            value={FilterCadena}
                            setValue={setFilterCadena}>
                            --TODOS--
                        </SelectSimple>
                    </div>

                    <div className='col-lg-2 '>
                        <label>Tienda</label>
                    </div>
                    <div className='col-lg-4 '>
                        <div className='form-group'>
                            <SelectSimple
                                className={"form-control-text"}
                                lista={ListaComboTienda}
                                value={FilterTienda}
                                setValue={setFilterTienda}>
                                --TODOS--
                            </SelectSimple>
                        </div>
                    </div>
                </div>
                <div className='row mt-2 mb-2'>
                    <div className='col-lg-6'>
                        <div className='form-group'>

                            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)}>Consultar</button>
                            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)}  >Exportar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
                        enableRowActions
                        renderRowActions={({ row, table }) => (
                            <div style={{ width: 100 }}>
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <IconButton onClick={() => Controller.toVerDetalles(router, row.original)}>
                                            <ViewIcon titleAccess="Ver detalles" />
                                        </IconButton>
                                    </div>
                                    <div className="col-lg-4">
                                        <IconButton onClick={() => Controller.toVerFotos(router, row.original)}>
                                            <PhotoCameraIcon titleAccess="Ver fotos" />
                                        </IconButton>
                                    </div>
                                    <div className="col-lg-4">
                                        <IconButton onClick={() => Controller.toRegistrarIncidencia(router, row.original)}>
                                            <EditNoteIcon titleAccess="Registrar incidencia" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        )} />
                </div>
            </div>
        </ContentBody>
    </div>
    )
}
