'use client'

import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/pregunta-controller";
import * as Repository from "../../../repositories/pregunta-repository";
import SelectSimple from '../layout/SelectSimple';
export default function ConsultaPregunta(): React.JSX.Element {
  const router = useNavigate()
  const [ListaComboTipoGrupo, setListaComboTipoGrupo] = React.useState([])
  const [ListaComboTipoArea, setListaComboTipoArea] = React.useState([])
  const [FilterTipoGrupo, setFilterTipoGrupo] = React.useState(0)
  const [FilterTipoArea, setFilterTipoArea] = React.useState(0)
  const [ListaConsulta, setListaConsulta] = React.useState([])
 
  const onChangeTipoArea = (e: any) => {

    setFilterTipoArea(e.target.value)
    if (e.target.value == 1) { //SUPERVISION
      Controller.getListaComboTipoGrupoSupervision(e.target.value, setListaComboTipoGrupo);
    } else {
      Controller.getListaComboTipoGrupoIncidencia(e.target.value, setListaComboTipoGrupo);
    }

  };

  React.useEffect(() => {
    Controller.doConsultar(setListaConsulta, FilterTipoArea, FilterTipoGrupo)

    Controller.getListaComboTipoArea(setListaComboTipoArea)

  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Consulta Pregunta"} homePath={"Home"} currentPath={"Mantenimiento Pregunta"} >
    </ContentHeader>
    <ContentBody title={"Consulta Pregunta"}>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Tipo Area</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-goup'>
            <SelectSimple
              className={"form-control-text"}
              lista={ListaComboTipoArea}
              value={FilterTipoArea}
              setValue={setFilterTipoArea}
              onChangeSection={onChangeTipoArea}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
        <div className='col-lg-2 '>
          <label>Tipo Grupo</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <SelectSimple className={"form-control-text"}
              lista={ListaComboTipoGrupo}
              value={FilterTipoGrupo}
              setValue={setFilterTipoGrupo}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-gl-6'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterTipoArea, FilterTipoGrupo)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterTipoArea, FilterTipoGrupo)}  >Exportar</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 100 }}>
              <div className='row'>
              <div className="col-lg-4">
                <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                  <EditIcon titleAccess="Editar" />
                </IconButton>
                </div>
                <div className="col-lg-4">
                <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                  <DeleteIcon titleAccess="Eliminar" />
                </IconButton>
              </div>
              </div>
                </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}
