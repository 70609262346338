import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/auditoria-repository";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { CODE_SERVER_RETURN } from '../utils/contants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function doConsultar(setListaConsulta: any, FilterCadena: string, FilterTienda: string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var dataResponse = await Repository.getData(FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)
    setListaConsulta(dataResponse)
}

export function toConsulta(router: NavigateFunction) {
    router("/main/auditoria/consulta")
}

export async function toVerDetalles(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeAuditoria
    router("/main/auditoria/detalle/" + codeEditar)
}

export async function toVerFotos(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeAuditoria
    router("/main/auditoria/detallefoto/" + codeEditar)
}

export async function toRegistrarIncidencia(router: NavigateFunction, row: any) {
    var codeEditar = row.CodeAuditoria
    router("/main/auditoria/incidencia/" + codeEditar)
}

export async function getListaComboTienda(setListaComboTienda: any) {
    var dataResponse = await Repository.getListaComboTienda()
    setListaComboTienda(dataResponse)
}


export async function getListaAuditoriaDetalleByCode(code: any, setAuditoria: any, setListaDetalleAuditoria: any) {
    var dataResponse = await Repository.getListaAuditoriaDetalleByCode(code)
    setAuditoria(dataResponse.Auditoria)
    setListaDetalleAuditoria(dataResponse.AuditoriaDetalle)
}

export async function getListaAuditoriaDetalleFotoByCode(code: any, setAuditoria: any, setListaDetalleFotoAuditoria: any) {
    var dataResponse = await Repository.getListaAuditoriaDetalleFotoByCode(code)
    setAuditoria(dataResponse.Auditoria)
    setListaDetalleFotoAuditoria(dataResponse.AuditoriaDetalleFoto)
}

export async function getListaComboCadena(setListaComboCadena: any) {
    var dataResponse = await Repository.getListaComboCadena()
    setListaComboCadena(dataResponse)
}

export async function doExportar(FilterCadena: string, FilterTienda: string, FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var dataResponse = await Repository.doExport(FilterCadena, FilterTienda, FilterFechaDesde, FilterFechaHasta)
}

export async function Mensaje() {
    const MySwal = withReactContent(Swal)
    var message = "Tiene que completar los campos"
    MySwal.fire({
        title: <p>{message}</p>,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
    }
    )
}


export async function save(router: NavigateFunction, codeAuditoria: string, descripcion: string, fechaProgramado: Date) {
    const MySwal = withReactContent(Swal)
    var question = "¿Desea guardar el registro?"
    MySwal.fire({
        title: <p>{question}</p>,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "Cancelar"
    }).then(async (result: any) => {
        if (result.isConfirmed) {
            const objSave = {
                CodeAuditoria: codeAuditoria,
                Descripcion: descripcion,
                FechaProgramado: fechaProgramado,
            }
            var dataResponse = await Repository.save(objSave)
            if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
                MySwal.fire({
                    title: <p>{dataResponse.Message}</p>,
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                });
            } else if (dataResponse.Code == CODE_SERVER_RETURN.ERROR) {
                MySwal.fire({
                    title: <p>{dataResponse.Message}</p>,
                    icon: "error",
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: "OK",

                });
            } else {
                toast.success('Guardado satisfactoriamente!')
                toConsulta(router)
            }
        }
    })
}
