"use client";

import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/programacionusuario-controller";
import React from "react";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import * as Repository from "../../../repositories/programacionusuario-repository";
import MaterialReactTable from "material-react-table";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  isMuiElement,
} from "@mui/material";
import SelectSimple from "../layout/SelectSimple";
import Select from "react-select";
import Label from "../layout/Label";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { addDays, startOfMonth, endOfMonth } from "date-fns";
import es from "date-fns/locale/es"; // the locale you want

registerLocale("es", es);
export default function NuevoProgramacionUsuario() {
  const [ListaComboTareas, setListaComboTareas] = React.useState([]);
  const [ListaComboTienda, setListaComboTienda] = React.useState([]);
  const [ListaComboUsuario, setListaComboUsuario] = React.useState([]);
  const [ListaComboSemana, setListaComboSemana] = React.useState([]);
  const [ListaComboDiasSemana, setListaComboDiasSemana] = React.useState([]);
  const { code } = useParams();
  const Router = useNavigate();
  const [ListaConfiguracion, setListaConfiguracion] = React.useState([]);
  const [codeProgramacionUsuario, setCodeProgranacionUsuario] =
    React.useState("");
  const [codeUsuario, setCodeUsuario] = React.useState([]);
  const [nombreUsuario, setNombreUsuario] = React.useState("");
  const [idSemana, setIdSemana] = React.useState(0);
  const [periodo, setPeriodo] = React.useState(new Date());
  const [tiendas, setTiendas] = React.useState([]);
  const [tareas, setTareas] = React.useState("");
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [tituloVentana, setTituloVentana] = React.useState(
    "Nuevo Programacion Usuario"
  );
  const [valcodeUsuario, setValCodeUsuario] = React.useState(false);
  const [frecuencia, setFrecuencia] = React.useState(0);
  const [diaSemana, setDiaSemana] = React.useState("");
  const [selRango, setSelRango] = React.useState([
    { startDate: new Date(), endDate: new Date(), key: "selection" },
  ]);

  const startOfCurrentMonth = startOfMonth(new Date());
  const endOfCurrentMonth = endOfMonth(new Date());

  const FRECUENCIA_SEMANAL = 1;
  const FRECUENCIA_MENSUAL = 2;

  React.useEffect(() => {
    Controller.getListaComboTareas(setListaComboTareas);
    Controller.getListaComboTienda(setListaComboTienda);
    Controller.getListaComboUsuario(setListaComboUsuario);
    //Controller.getListaComboSemana(setListaComboSemana);
    Controller.getListaComboDiasSemana(setListaComboDiasSemana);

    if (code != "nuevo") {
      setTituloVentana("Editar Programacion Usuario");
      setCodeProgranacionUsuario(code!);
      Controller.getByCode(code, setNombreUsuario, setCodeUsuario, setPeriodo);
    }
  }, []);

  function ValidarCampos() {
    var Result = true;
    var mensaje;
    setIsSubmit(true);

    console.log("Code Usuario: " + codeUsuario);

    if (codeUsuario == null || codeUsuario.length == 0) {
      Result = false;
      mensaje = "Seleccione el usuario";
    } else if (ListaConfiguracion.length == 0) {
      Result = false;
      mensaje = "Debe agregar registros a la configuración";
    }

    /*if (codeUsuario == "" || codeUsuario == "0" || codeUsuario == null) {
      Result = false;
      setValCodeUsuario(false);
    } else {
      setValCodeUsuario(true);
    }*/
    if (Result) {
      Controller.save(
        Router,
        codeProgramacionUsuario,
        codeUsuario,
        periodo,
        selRango,
        ListaConfiguracion
      );
    } else {
      Controller.Mensaje(mensaje);
    }
  }

  function handleSelect(ranges: any) {
    //setSelRango(ranges);
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }

  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(Router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-4">
                          <Label required={true}> Usuario</Label>
                        </div>
                        <div className="col-md-8">
                          <div className="form-group">
                            <Select
                              value={codeUsuario}
                              closeMenuOnSelect={false}
                              options={ListaComboUsuario}
                              isClearable
                              onChange={(e: any) => {
                                setCodeUsuario(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-4">
                          <Label required={true}> Periodo </Label>
                        </div>

                        <div className="col-md-8">
                          <div className="form-group">
                            <DatePicker
                              locale="es"
                              className="form-control-text"
                              popperPlacement="top-end"
                              selected={periodo}
                              onChange={(date: any) => setPeriodo(date)}
                              dateFormat={"yyyy-MM"}
                              showMonthYearPicker
                              showFullMonthYearPicker
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-md-8">
                          <DateRange
                            ranges={selRango}
                            locale={es}
                            minDate={startOfCurrentMonth}
                            maxDate={endOfCurrentMonth}
                            onChange={(item: any) =>
                              setSelRango([item.selection])
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row mt-3">
                    <div className="col-lg-2">
                      <Label required={true}> Semana del año</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={idSemana}
                          setValue={setIdSemana}
                          lista={ListaComboSemana}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div> */}

                  <div className="row ">
                    <div className="col-lg-6">
                      <h3>Configuración</h3>
                      <hr />
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-lg-2">
                      <Label>Día semana</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={diaSemana}
                          setValue={setDiaSemana}
                          lista={ListaComboDiasSemana}
                        >
                          --Seleccione--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2">
                      <Label> Tiendas</Label>
                    </div>
                    <div className="col-lg-4">
                      <Select
                        value={tiendas}
                        closeMenuOnSelect={false}
                        options={ListaComboTienda}
                        isMulti
                        onChange={(e: any) => {
                          setTiendas(e);
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Tareas </Label>
                    </div>
                    <div className="col-md-4">
                      <Select
                        closeMenuOnSelect={false}
                        options={ListaComboTareas}
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}                    
                        onChange={(e: any) => {
                          setTareas(e);
                        }}
                      />
                    </div>

                    <div className="col-lg-1">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMember(
                            diaSemana,
                            tiendas,
                            tareas,
                            ListaComboDiasSemana,
                            ListaConfiguracion,
                            setListaConfiguracion
                          )
                        }
                      >
                        Agregar
                      </button>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-md-12">
                      <MaterialReactTable
                        columns={Repository.GetMColumns()}
                        data={ListaConfiguracion}
                        enableRowActions
                        enablePagination={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        renderRowActions={({ row, table }) => (
                          <div>
                            <IconButton
                              onClick={() =>
                                Controller.toQuestionEliminarTareas(
                                  row.original,
                                  ListaConfiguracion,
                                  setListaConfiguracion
                                )
                              }
                            >
                              <DeleteIcon titleAccess="Eliminar" />
                            </IconButton>
                          </div>
                        )}
                      />
                      <div className="row mt-5">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <button
                              className="btn btn-default"
                              onClick={() => Controller.toConsulta(Router)}
                            >
                              Cancelar
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => ValidarCampos()}
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
