import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/usuario-repository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  CODE_SERVER_RETURN,
  UPLOAD_FILES,
  UPLOAD_FILESALTASUNAT,
  UPLOAD_FILESCONSTANCIA,
  UPLOAD_FILESCONTRATO,
  UPLOAD_FILESCS,
  UPLOAD_FILESDNI,
} from "../utils/contants";
import moment from "moment";

export function toNuevo(router: NavigateFunction) {
  router("/main/usuario/mantenimiento/nuevo");
}
export function toConsulta(router: NavigateFunction) {
  router("/main/usuario/consulta");
}
export async function toEditar(router: NavigateFunction, row: any) {
  var codeEditar = row.CodeUsuario;
  router("/main/usuario/mantenimiento/" + codeEditar);
}
export async function toQuestionInactivaActiva(
  row: any,
  setListaConsulta: any
) {
  const MySwal = withReactContent(Swal);
  var question =
    row.Estado == 1
      ? "¿Desea eliminar el registro?"
      : "¿Desea activar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then((result: any) => {
    if (result.isConfirmed) {
      toInactivaActiva(row, setListaConsulta);
    }
  });
}
async function toInactivaActiva(row: any, setListaConsulta: any) {
  var codeEliminar = row.CodeUsuario;
  const objSave = {
    CodeUsuario: codeEliminar,
  };
  if (row.Estado == 1) {
    var dataResponse = await Repository.inactiva(objSave);
  } else if (row.Estado == 0) {
    var dataResponse = await Repository.activa(objSave);
  }
  doConsultar(setListaConsulta, "", "", "", true);
}
export async function toQuestionrReset(row: any, setListaConsulta: any) {
  const MySwal = withReactContent(Swal);
  var question = row.Estado == 1 ? "¿Desea actualizar Clave?" : "";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then((result: any) => {
    if (result.isConfirmed) {
      ResetClave(row, setListaConsulta);
    }
  });
}

async function ResetClave(row: any, setListaConsulta: any) {
  var reset = row.CodeUsuario;
  const objSave = {
    CodeUsuario: reset,
  };
  if (row.Estado == 1) {
    var dataResponse = await Repository.resetclave(objSave);
  }
  doConsultar(setListaConsulta, "", "", "", true);
}

export async function getListaComboTipoDocumento(
  setListaComboTipoDocumento: any
) {
  var dataResponse = await Repository.getListaComboTipoDocumento();
  setListaComboTipoDocumento(dataResponse);
}

export async function getByCode(
  code: any,
  setCodeEmpresa: any,
  setIdPerfil: any,
  setCodeCargoPersona: any,
  setNombres: any,
  setApellidos: any,
  setNombreUsuario: any,
  setCorreo: any,
  setPrimerNombre: any,
  setSegundoNombre: any,
  setApellidoPaterno: any,
  setApellidoMaterno: any,
  setHorario: any,
  setRefrigerio: any,
  setDescanso: any,
  setDireccion: any,
  setUbigeo: any,
  setListaComboUbigeo: any,
  setProvincia: any,
  setListaComboProvincia: any,
  setDepartamento: any,
  setEmpresaAseguradora: any,
  setUrlFileContrato: any,
  setUrlFileCS: any,
  setUrlFileConstancia: any,
  setUrlFileDNI: any,
  setUrlFileAltaSunat: any,
  setFechaInicioCs: any,
  setFechaFinCs: any,
  setFechaIngreso: any,
  setFechaInicioContrato: any,
  setFechaFinContrato: any,
  setFechaInicioCtr: any,
  setFechaFinCtr: any,
  setTipoDocumentoIdentidad: any,
  setNumeroDocumento: any,
  setUrlImagen: any,
  setCodeMarcaRepresenta: any,
  setCodeCliente: any,
  setCarnetSanidad: any,
  setDiasTrabajo: any
) {
  var dataResponse = await Repository.getByCode(code);
  if (dataResponse != null) {
    setCodeEmpresa(dataResponse.CodeEmpresa);
    setIdPerfil(dataResponse.IdPerfil);
    setCodeCargoPersona(dataResponse.CodeCargoPersona);
    setNombres(dataResponse.Nombres);
    setApellidos(dataResponse.Apellidos);
    setNombreUsuario(dataResponse.NombreUsuario);
    setCorreo(dataResponse.Correo);
    setPrimerNombre(dataResponse.PrimerNombre);
    setSegundoNombre(dataResponse.SegundoNombre);
    setApellidoPaterno(dataResponse.ApellidoPaterno);
    setApellidoMaterno(dataResponse.ApellidoMaterno);
    setHorario(dataResponse.Horario);
    setRefrigerio(dataResponse.Refrigerio);
    setDescanso(dataResponse.Descanso);
    setDireccion(dataResponse.Direccion);
    setUbigeo(dataResponse.IdUbigeo);
    setProvincia(dataResponse.IdProvincia);
    setDepartamento(dataResponse.IdDepartamento);
    setEmpresaAseguradora(dataResponse.IdEmpresaAseguradora);
    setUrlFileContrato(dataResponse.UrlFileContrato);
    setUrlFileCS(dataResponse.UrlFileCS);
    setUrlFileConstancia(dataResponse.UrlFileConstancia);
    setUrlFileDNI(dataResponse.UrlFileDNI);
    setUrlFileAltaSunat(dataResponse.UrlFileAltaSunat);
    setCarnetSanidad(dataResponse.CarnetSanidad);
    setDiasTrabajo(dataResponse.DiasTrabajo);

    if (dataResponse.FechaInicioCS != null) {
      setFechaInicioCs(moment(dataResponse.FechaInicioCS).toDate());
    }
    if (dataResponse.FechaFinCS != null) {
      setFechaFinCs(moment(dataResponse.FechaFinCS).toDate());
    }

    if (dataResponse.FechaIngreso != null) {
      setFechaIngreso(moment(dataResponse.FechaIngreso).toDate());
    }
    if (dataResponse.FechaInicioContrato != null) {
      setFechaInicioContrato(moment(dataResponse.FechaInicioContrato).toDate());
    }
    if (dataResponse.FechaFinContrato != null) {
      setFechaFinContrato(moment(dataResponse.FechaFinContrato).toDate());
    }

    if (dataResponse.FechaInicioCTR != null) {
      setFechaInicioCtr(moment(dataResponse.FechaInicioCTR).toDate());
    }
    if (dataResponse.FechaFinCTR != null) {
      setFechaFinCtr(moment(dataResponse.FechaFinCTR).toDate());
    }

    setTipoDocumentoIdentidad(dataResponse.TipoDocumentoIdentidad);
    setNumeroDocumento(dataResponse.NumeroDocumentoIdentidad);
    setUrlImagen(dataResponse.UrlImagen);

    if (
      dataResponse.IdDepartamento != null &&
      dataResponse.IdDepartamento != 0
    ) {
      console.log("Entro a llenar provincia");
      getListaComboProvincia(
        dataResponse.IdDepartamento,
        setListaComboProvincia
      );
    }
    if (dataResponse.IdProvincia != null && dataResponse.IdProvincia != 0) {
      getListaComboUbigeo(dataResponse.IdProvincia, setListaComboUbigeo);
    }

    var dataResponseMarcaSelect = [];
    if (dataResponse.UsuarioMarca != null) {
      dataResponseMarcaSelect = dataResponse.UsuarioMarca.map(
        (element: any) => {
          var item = {
            value: element.CodeMarcaRepresenta,
            label: element.NombreMarcaRepresenta,
          };
          return item;
        }
      );
    }

    setCodeMarcaRepresenta(dataResponseMarcaSelect);

    var dataResponseClienteSelect = [];
    if (dataResponse.UsuarioCliente != null) {
      dataResponseClienteSelect = dataResponse.UsuarioCliente.map(
        (element: any) => {
          var item = {
            value: element.CodeCliente,
            label: element.NombreCliente,
          };
          return item;
        }
      );
    }
    setCodeCliente(dataResponseClienteSelect);
  }
}
export async function doConsultar(
  setListaConsulta: any,
  FilterEmpresa: string,
  FilterApeNom: string,
  FilterNombreUsuario: string,
  SoloActivos: boolean
) {
  var dataResponse = await Repository.getData(
    FilterEmpresa,
    FilterApeNom,
    FilterNombreUsuario,
    SoloActivos
  );
  setListaConsulta(dataResponse);
}

export async function getListaComboEmpresa(setListaComboEmpresa: any) {
  var dataResponse = await Repository.getListaComboEmpresa();
  setListaComboEmpresa(dataResponse);
}
export async function getListaComboPerfil(setListaComboPerfil: any) {
  var dataResponse = await Repository.getListaComboPerfil();
  setListaComboPerfil(dataResponse);
}

export async function getListaComboCliente(setListaComboCliente: any) {
  var dataResponse = await Repository.getListaComboCliente();

  var dataResponseSelect = dataResponse.map((item: any) => {
    var newItem = {
      value: item.Id,
      label: item.Texto,
    };
    return newItem;
  });
  setListaComboCliente(dataResponseSelect);
}

export async function getUsuarioInfo(setUsuario: any) {
  var dataResponse = await Repository.getUsuarioInfo();

  setUsuario({
    Perfilusuario: dataResponse.IdPerfil,
    NombreCompleto: dataResponse.NombreCompleto,
    NombrePerfil: dataResponse.NombrePerfil,
    OpcionesMenu: dataResponse.OpcionesMenu
  });
}

export async function save(
  router: NavigateFunction,
  idPerfil: string,
  codeUsuario: string,
  codeEmpresa: string,
  nombres: string,
  apellidos: string,
  nombreUsuario: string,
  correo: string,
  tipoDocumentoIdentidad: number,
  numeroDocumento: string,
  fechaIngreso: any,
  fechaInicioContrato: any,
  fechaFinContrato: any,
  fechaInicioCs: any,
  fechaFinCs: any,
  fechaInicioCtr: any,
  fechaFinCtr: any,
  codeCargoPersona: string,
  codeMarcaRepresenta: any,
  marcaRepresenta: any,
  codeCliente: any,
  primernombre: string,
  segundonombre: string,
  apellidoPaterno: string,
  apellidoMaterno: string,
  horario: string,
  refrigerio: string,
  descanso: string,
  direccion: string,
  ubigeo: string,
  idEmpresaAseguradora: number,
  file: any,
  urlImagen: string,
  fileContrato: any,
  urlFileContrato: string,
  fileCS: any,
  urlFileCS: string,
  fileConst: any,
  urlFileConstancia: string,
  fileDNI: any,
  urlFileDNI: string,
  fileAltaSunat: any,
  urlFileAltaSunat: string,
  carnetSanidad: string,
  diasTrabajo: string
) {
  const MySwal = withReactContent(Swal);
  var question = "¿Desea guardar el registro?";
  MySwal.fire({
    title: <p>{question}</p>,
    icon: "question",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: "Sí",
    cancelButtonText: "Cancelar",
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      // Subir Archivo
      var URLFile = urlImagen == null || urlImagen == "" ? null : urlImagen;
      if (file != null) {
        const formDataToUpload = new FormData();
        const tipoBucket = UPLOAD_FILES.USUARIO_FOTO_AVATAR; // Usuario
        formDataToUpload.append("FileImage", file);
        formDataToUpload.append("TipoBucket", tipoBucket.toString());
        var dataResponseURL = await Repository.uploadFile(formDataToUpload);
        if (dataResponseURL != null && dataResponseURL.Code === 0) {
          URLFile = dataResponseURL.Data.PathFile;
        }
      }

      // Subir Archivo Contrato
      var URLFIlECONTRATO =
        urlFileContrato == null || urlFileContrato == ""
          ? "-"
          : urlFileContrato;
      if (fileContrato != null) {
        const formDataToUpload = new FormData();
        const tipoBucket = UPLOAD_FILESCONTRATO.USUARIO_ADJUNTAR_CONTRATO; // Documento
        formDataToUpload.append("FileImage", fileContrato);
        formDataToUpload.append("TipoBucket", tipoBucket.toString());
        var dataResponseURL = await Repository.uploadFile(formDataToUpload);
        if (dataResponseURL != null && dataResponseURL.Code === 0) {
          URLFIlECONTRATO = dataResponseURL.Data.PathFile;
        }
      }

      // Subir Archivo Carnet Sanidad
      var URLFILECARNETS =
        urlFileCS == null || urlFileCS == "" ? "-" : urlFileCS;
      if (fileCS != null) {
        const formDataToUpload = new FormData();
        const tipoBucket = UPLOAD_FILESCS.USUARIO_ADJUNTAR_CS; // Documento
        formDataToUpload.append("FileImage", fileCS);
        formDataToUpload.append("TipoBucket", tipoBucket.toString());
        var dataResponseURL = await Repository.uploadFile(formDataToUpload);
        if (dataResponseURL != null && dataResponseURL.Code === 0) {
          URLFILECARNETS = dataResponseURL.Data.PathFile;
        }
      }

      // Subir Archivo Constancia
      var URLFILECONSTANCIA =
        urlFileConstancia == null || urlFileConstancia == ""
          ? "-"
          : urlFileConstancia;
      if (fileConst != null) {
        const formDataToUpload = new FormData();
        const tipoBucket = UPLOAD_FILESCONSTANCIA.USUARIO_ADJUNTAR_CONSTANCIA; // Documento
        formDataToUpload.append("FileImage", fileConst);
        formDataToUpload.append("TipoBucket", tipoBucket.toString());
        var dataResponseURL = await Repository.uploadFile(formDataToUpload);
        if (dataResponseURL != null && dataResponseURL.Code === 0) {
          URLFILECONSTANCIA = dataResponseURL.Data.PathFile;
        }
      }

      // Subir Archivo DNI
      var URLFILEDNI =
        urlFileDNI == null || urlFileDNI == "" ? "-" : urlFileDNI;
      if (fileDNI != null) {
        const formDataToUpload = new FormData();
        const tipoBucket = UPLOAD_FILESDNI.USUARIO_ADJUNTAR_DNI; // Documento
        formDataToUpload.append("FileImage", fileDNI);
        formDataToUpload.append("TipoBucket", tipoBucket.toString());
        var dataResponseURL = await Repository.uploadFile(formDataToUpload);
        if (dataResponseURL != null && dataResponseURL.Code === 0) {
          URLFILEDNI = dataResponseURL.Data.PathFile;
        }
      }

      // Subir Archivo Carnet Alta Sunat
      var URLFILEALTASUNAT =
        urlFileAltaSunat == null || urlFileAltaSunat == ""
          ? "-"
          : urlFileAltaSunat;
      if (fileAltaSunat != null) {
        const formDataToUpload = new FormData();
        const tipoBucket = UPLOAD_FILESALTASUNAT.USUARIO_ADJUNTAR_ALTASUNAT; // Documento
        formDataToUpload.append("FileImage", fileAltaSunat);
        formDataToUpload.append("TipoBucket", tipoBucket.toString());
        var dataResponseURL = await Repository.uploadFile(formDataToUpload);
        if (dataResponseURL != null && dataResponseURL.Code === 0) {
          URLFILEALTASUNAT = dataResponseURL.Data.PathFile;
        }
      }
      console.log("Code a actualizar: " + codeUsuario);
      var listMarcaRepresenta = [];
      if (codeMarcaRepresenta != null && codeMarcaRepresenta.length > 0) {
        listMarcaRepresenta = codeMarcaRepresenta.map((item: any) => {
          var newItem = {
            CodeMarcaRepresenta: item.value,
          };
          return newItem;
        });
      }

      var listaCliente = [];
      if (codeCliente != null && codeCliente.length > 0) {
        listaCliente = codeCliente.map((item: any) => {
          var newItem = {
            CodeCliente: item.value,
          };
          return newItem;
        });
      }

      if (codeUsuario != null && codeUsuario != "") {
        const objSave = {
          CodeUsuario: codeUsuario,
          CodeEmpresa: codeEmpresa,
          IdPerfil: Number.parseInt(idPerfil),
          Nombres:
            segundonombre.trim() == ""
              ? primernombre
              : primernombre + " " + segundonombre,
          Apellidos: apellidoPaterno + " " + apellidoMaterno,
          NombreUsuario: nombreUsuario,
          Correo: correo,
          TipoDocumentoIdentidad: tipoDocumentoIdentidad,
          NumeroDocumentoIdentidad: numeroDocumento,
          UrlImagen: URLFile,
          FechaIngreso: fechaIngreso,
          FechaInicioContrato: fechaInicioContrato,
          FechaFinContrato: fechaFinContrato,
          FechaInicioCs: fechaInicioCs,
          FechaFinCs: fechaFinCs,
          FechaInicioCtr: fechaInicioCtr,
          FechaFinCtr: fechaFinCtr,
          CodeCargoPersona: codeCargoPersona,
          MarcaRepresenta: listMarcaRepresenta,
          Cliente: listaCliente,
          Primernombre: primernombre,
          Segundonombre: segundonombre,
          ApellidoPaterno: apellidoPaterno,
          ApellidoMaterno: apellidoMaterno,
          Horario: horario,
          Refrigerio: refrigerio,
          Descanso: descanso,
          Direccion: direccion,
          IdUbigeo: ubigeo == "" ? null : ubigeo,
          IdEmpresaAseguradora: idEmpresaAseguradora,
          UrlFileContrato: URLFIlECONTRATO,
          UrlFileCS: URLFILECARNETS,
          UrlFileConstancia: URLFILECONSTANCIA,
          UrlFileDNI: URLFILEDNI,
          UrlFileAltaSunat: URLFILEALTASUNAT,
          CarnetSanidad: carnetSanidad,
          DiasTrabajo: diasTrabajo,
        };
        var dataResponse = await Repository.update(objSave);
        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        } else {
          toConsulta(router);
        }
      } else {
        const objSave = {
          CodeEmpresa: codeEmpresa,
          IdPerfil: Number.parseInt(idPerfil),
          Nombres:
            segundonombre.trim() == ""
              ? primernombre
              : primernombre + " " + segundonombre,
          Apellidos: apellidoPaterno + " " + apellidoMaterno,
          NombreUsuario: nombreUsuario,
          Correo: correo,
          TipoDocumentoIdentidad: tipoDocumentoIdentidad,
          UrlImagen: URLFile,
          NumeroDocumentoIdentidad: numeroDocumento,
          FechaIngreso: fechaIngreso,
          FechaInicioContrato: fechaInicioContrato,
          FechaFinContrato: fechaFinContrato,
          FechaInicioCs: fechaInicioCs,
          FechaFinCs: fechaFinCs,
          FechaInicioCtr: fechaFinCtr,
          FechaFinCtr: fechaFinCtr,
          CodeCargoPersona: codeCargoPersona,
          MarcaRepresenta: listMarcaRepresenta,
          Cliente: listaCliente,
          Primernombre: primernombre,
          Segundonombre: segundonombre,
          ApellidoPaterno: apellidoPaterno,
          ApellidoMaterno: apellidoMaterno,
          Horario: horario,
          Refrigerio: refrigerio,
          Descanso: descanso,
          Direccion: direccion,
          IdUbigeo: ubigeo == "" ? null : ubigeo,
          IdEmpresaAseguradora: idEmpresaAseguradora,
          UrlFileContrato: URLFIlECONTRATO,
          UrlFileCS: URLFILECARNETS,
          UrlFileConstancia: URLFILECONSTANCIA,
          UrlFileDNI: URLFILEDNI,
          UrlFileAltaSunat: URLFILEALTASUNAT,
          CarnetSanidad: carnetSanidad,
          DiasTrabajo: diasTrabajo,
        };
        var dataResponse = await Repository.save(objSave);

        if (dataResponse.Code == CODE_SERVER_RETURN.VAL) {
          MySwal.fire({
            title: <p>{dataResponse.Message}</p>,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        } else {
          toConsulta(router);
        }
      }
    }
  });
}
export async function Mensaje() {
  const MySwal = withReactContent(Swal);
  var message = "Tiene que completar los campos";
  MySwal.fire({
    title: <p>{message}</p>,
    icon: "warning",
    showConfirmButton: true,
    confirmButtonText: "Aceptar",
  });
}

export async function doExportar(
  FilterEmpresa: string,
  FilterApeNom: string,
  FilterNombreUsuario: string,
  SoloActivos: boolean
) {
  Repository.doExport(FilterEmpresa, FilterApeNom, FilterNombreUsuario, SoloActivos);
}

export async function doCargarPlantillaUsuario(

) {
  Repository.doCargarPlantillaUsuario();
}


export async function getListaComboCargoPersona(
  setListaComboCargoPersona: any
) {
  var dataResponse = await Repository.getListaComboCargoPersona();
  setListaComboCargoPersona(dataResponse);
}
export async function getListaComboMarcaRepresenta(
  setListaComboMarcaRepresenta: any
) {
  var dataResponse = await Repository.getListaComboMarcaRepresenta();

  var dataResponseSelect = dataResponse.map((item: any) => {
    var newItem = {
      value: item.Id,
      label: item.Texto,
    };
    return newItem;
  });

  setListaComboMarcaRepresenta(dataResponseSelect);
}

export async function getListaComboDepartamento(
  setListaComboDepartamento: any
) {
  var dataResponse = await Repository.getListaComboDepartamento();
  setListaComboDepartamento(dataResponse);
}
export async function getListaComboProvincia(
  departamento: string,
  setListaComboProvincia: any
) {
  if (departamento != "-" && departamento != "0") {
    var dataResponse = await Repository.getListaComboProvincia(departamento);
    setListaComboProvincia(dataResponse);
  } else {
    setListaComboProvincia(dataResponse);
  }
}

export async function getListaComboUbigeo(
  ubigeo: string,
  setListaComboUbigeo: any
) {
  if (ubigeo != "-" && ubigeo != "0") {
    var dataResponse = await Repository.getListaComboUbigeo(ubigeo);
    setListaComboUbigeo(dataResponse);
  } else {
    setListaComboUbigeo(dataResponse);
  }
}

export async function getListaComboEmpresaAseguradora(
  setListaComboEmpresaAseguradora: any
) {
  var dataResponse = await Repository.getListaComboEmpresaAseguradora();
  setListaComboEmpresaAseguradora(dataResponse);
}

export async function importarUsuarios(
  router: NavigateFunction,
  fileImportar: any
) {
  if (fileImportar != null) {
    const formDataToUpload = new FormData();
    formDataToUpload.append("FileExcel", fileImportar);

    var dataResponseURL = await Repository.importarUsuarios(formDataToUpload);
    if (dataResponseURL != null && dataResponseURL.Code === 0) {
      toConsulta(router);
    }
  }
}
