'use client'

import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/marcarepresenta-controller";
import * as Repository from "../../../repositories/marcarepresenta.repository";
import Textbox from '../layout/Textbox';
export default function ConsultaMarcaRepresenta(): React.JSX.Element {
  const router = useNavigate()
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [FilterNombreMarcaRepresenta, setFilterNombreMarcaRepresenta] = React.useState('')
  React.useEffect(() => {
    Controller.doConsultar(setListaConsulta, FilterNombreMarcaRepresenta)
  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Consulta Marca Representa"} homePath={"Home"} currentPath={"Mantenimiento Marca Representa"} >
    </ContentHeader>
    <ContentBody title={"Consulta Marca Representa"}>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Nombres Marca</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <Textbox
              value={FilterNombreMarcaRepresenta}
              setValue={setFilterNombreMarcaRepresenta} />
          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-lg-6'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterNombreMarcaRepresenta)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterNombreMarcaRepresenta)}  >Exportar</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 100 }}>
              <div className='row'>
              <div className="col-lg-4">
                <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                  <EditIcon titleAccess="Editar" />
                </IconButton>
                </div>
                <div className="col-lg-4">
                <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                  <DeleteIcon titleAccess="Eliminar" />
                </IconButton>
              </div>
              </div>
              </div>
            )} />
        </div>
        
      </div>
    </ContentBody>
  </div>
  )
}
