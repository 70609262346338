'use client'

import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import * as Controller from "../../../controllers/producto-controller";
import * as Repository from "../../../repositories/producto-repository";
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import SelectSimple from '../layout/SelectSimple';
import Textbox from '../layout/Textbox';
export default function ConsultaProducto() {
  const router = useNavigate()
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [ListaComboCategoria, setListaComboCategoria] = React.useState([])
  const [FilterCategoria, setFilterCategoria] = React.useState('')
  const [ListaComboMarca, setListaComboMarca] = React.useState([])
  const [FilterMarca, setFilterMarca] = React.useState('')
  const [ListaComboSubCategoria, setListaComboSubCategoria] = React.useState([])
  const [FilterSubCategoria, setFilterSubCategoria] = React.useState('')
  const [FilterEmpresa, setFilterEmpresa] = React.useState('')
  const [FilterNombreProducto, setFilterNombreProducto] = React.useState('')
  const [FileExcel, setFileExcel] = React.useState(null);


  const onChangeCodeMarca = (e: any) => {

    setFilterMarca(e.target.value)
    if (e.target.value != null && e.target.value != "" && e.target.value != "0") {
      Controller.getListaComboCategoria(e.target.value, setListaComboCategoria);
    } else {
      setListaComboCategoria([])
    }

    setListaComboSubCategoria([])
  };

  const onChangeCodeMasterCategoria = (e: any) => {
    setFilterSubCategoria(e.target.value)

  };
  const onChangeCodeCategoria = (e: any) => {

    setFilterCategoria(e.target.value)
    if (e.target.value != null && e.target.value != "" && e.target.value != "0") {
      Controller.getListaComboSubCategoria(e.target.value, setListaComboSubCategoria);
    } else {
      setListaComboSubCategoria([])
    }

  };

  React.useEffect(() => {
    Controller.getListaComboMarca(setListaComboMarca)

    Controller.doConsultar(setListaConsulta, FilterEmpresa, FilterSubCategoria, FilterMarca, FilterCategoria, FilterNombreProducto)
  }, [])

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    setFileExcel(fileToUpload);
  }
  return (<div className="content-wrapper">
    <ContentHeader title={"Consulta Producto"} homePath={"Home"} currentPath={"Mantenimiento Producto"} >
    </ContentHeader>
    <ContentBody title={"Consulta Producto"}>

      <div className='row'>

        <div className='col-lg-2 '>
          <label>Marca</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <SelectSimple
              value={FilterMarca}
              setValue={setFilterMarca}
              lista={ListaComboMarca}
              onChangeSection={onChangeCodeMarca}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
        <div className='col-lg-2 '>
          <label>Categoria</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <SelectSimple
              value={FilterCategoria}
              setValue={setFilterCategoria}
              lista={ListaComboCategoria}

              onChangeSection={onChangeCodeCategoria}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Sub Categoria</label>
        </div >
        <div className='col-lg-4 '>
          <div className='form-group'>
            <SelectSimple
              value={FilterSubCategoria}
              setValue={setFilterSubCategoria}
              lista={ListaComboSubCategoria}
              onChangeSection={onChangeCodeMasterCategoria}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
        <div className='col-lg-2 '>
          <label>Nombre Producto</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <Textbox
              value={FilterNombreProducto}
              setValue={setFilterNombreProducto} />
          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-lg-6'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterEmpresa, FilterSubCategoria, FilterMarca, FilterCategoria, FilterNombreProducto)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterEmpresa, FilterSubCategoria, FilterCategoria, FilterMarca, FilterNombreProducto)}  >Exportar</button>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-8">
              <div className="mt-2">
                <input
                  type="file"
                  id="fileExcel"
                  onChange={onChangeFileUpload}
                />
              </div>
            </div>
            <div className='col-lg-4'>
              <button
                className="btn btn-primary"
                onClick={() => Controller.importarProducto(router, FileExcel)}
              >
                Importar
              </button>
            </div>
            <div className='col-lg-6'>
              <button className='btn btn-info' onClick={() => Controller.doCargarPlantillaProducto()}  >Descargar Plantilla</button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 100 }}>
                <div className="row">
                  <div className='col-lg-4'>
                    <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                      <EditIcon titleAccess="Editar" />
                    </IconButton>
                  </div>
                  <div className="col-lg-4">
                    <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                      <DeleteIcon titleAccess="Eliminar" />
                    </IconButton>
                  </div>
                </div>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}
