import ContentHeader from "../layout/ContentHeader";
import * as Controller from "../../../controllers/tienda-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import Label from "../layout/Label";
import TextArea from "../layout/TextArea";
import { useParams, useNavigate } from "react-router-dom";

export default function NuevoTienda() {
  const [ListaComboCadenaMaster, setListaComboCadenaMaster] = React.useState(
    []
  );
  const [ListaComboTiendaMaster, setListaComboTiendaMaster] = React.useState(
    []
  );
  const { code } = useParams();
  const router = useNavigate();
  const [codeCadenaMaster, setCodeCadenaMaster] = React.useState("");
  const [codeTiendaMaster, setCodeTiendaMaster] = React.useState("");
  const [codeTienda, setCodeTienda] = React.useState("");
  const [codigoTienda, setCodigoTienda] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const [ubigeo, setUbigeo] = React.useState("");
  const [latitud, setLatitud] = React.useState(0.0);
  const [longitud, setLongitud] = React.useState(0.0);
  const [horarioDesde, setHorarioDesde] = React.useState("");
  const [horarioHasta, setHorarioHasta] = React.useState("");
  const [nombreComercial, setNombreComercial] = React.useState("");
  const [apellidoComercial, setApellidoComercial] = React.useState("");
  const [telefonoComercial, setTelefonicoComercial] = React.useState("");
  const [correoElectronicoComercial, setCorreoElectronicoComercial] =
    React.useState("");
  const [dniComercial, setDniComercial] = React.useState("");
  const [observacion, setObservacion] = React.useState("");
  const [tituloVentana, setTituloVentana] = React.useState("Nueva Tienda");
  const [ValCodeTiendaMaster, setValCodeTiendaMaster] = React.useState(false);
  const [ValCodeCadenaMaster, setValCodeCadenaMaster] = React.useState(false);
  const [ValhorarioDesde, setValHorarioDesde] = React.useState(false);
  const [ValNombreComercial, setValNombreComercial] = React.useState(false);
  const [ValApellidoComercial, setValApellidoComercial] = React.useState(false);
  const [ValTelefonoComercial, setValTelefonicoComercial] =
    React.useState(false);
  const [ValCorreoElectronicoComercial, setValCorreoElectronicoComercial] =
    React.useState(false);
  const [ValDniComercial, setValDniComercial] = React.useState(false);
  const [ValHorarioHasta, setValHorarioHasta] = React.useState(false);
  const [ValObservacion, setValObservacion] = React.useState(false);
  const [mode, setMode] = React.useState(0);
  const [nombreCadena, setNombreCadena] = React.useState("");
  const [nombreTienda, setNombreTienda] = React.useState("");
  const [nombreTiendaB2B, setNombreTiendaB2B] = React.useState("");
  const [lunes, setLunes] = React.useState(false);
  const [martes, setMartes] = React.useState(false);
  const [miercoles, setMiercoles] = React.useState(false);
  const [jueves, setJueves] = React.useState(false);
  const [viernes, setViernes] = React.useState(false);
  const [sabado, setSabado] = React.useState(false);
  const [domingo, setDomingo] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [messageError, setMessasgeError] = React.useState("");

  const onChangeCodeCadena = (e: any) => {
    setCodeCadenaMaster(e.target.value);
    Controller.getListaTiendaMaster(e.target.value, setListaComboTiendaMaster);
  };

  const onChangeCodeMaster = async (e: any) => {
    setCodeTiendaMaster(e.target.value);
    Controller.getByCodeTienda(
      e.target.value,
      setCodigoTienda,
      setDireccion,
      setUbigeo,
      setLatitud,
      setLongitud
    );
  };

  React.useEffect(() => {
    if (code != "nuevo") {
      setTituloVentana("Editar Tienda");
      setCodeTienda(code!);
      setMode(1);
      Controller.getByCode(
        code,
        setCodeTiendaMaster,
        setCodeCadenaMaster,
        setCodigoTienda,
        setDireccion,
        setUbigeo,
        setHorarioDesde,
        setHorarioHasta,
        setLunes,
        setMartes,
        setMiercoles,
        setJueves,
        setViernes,
        setSabado,
        setNombreComercial,
        setApellidoComercial,
        setTelefonicoComercial,
        setCorreoElectronicoComercial,
        setDniComercial,
        setObservacion,
        setNombreCadena,
        setNombreTienda,
        setLatitud,
        setLongitud
      );
    }
    Controller.getListaComboCadenaMaster(setListaComboCadenaMaster);
  }, []);

  function isValidEmail(email: string) {
    // Define a regular expression pattern for email validation.
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  function ValidateCampos() {
    var Result = true;
    setIsSubmit(true);


    if (correoElectronicoComercial != "" && !isValidEmail(correoElectronicoComercial)) {
      setMessasgeError("Correo no valido");
      Result = false;
      console.log("Correo mal")
    }

    if (code == "nuevo") {
      if (
        codeCadenaMaster == "" ||
        codeCadenaMaster == "0" ||
        codeCadenaMaster == null
      ) {
        Result = false;
        setValCodeCadenaMaster(false);
      } else {
        setValCodeCadenaMaster(true);
      }
      if (
        codeTiendaMaster == "" ||
        codeTiendaMaster == "0" ||
        codeTiendaMaster == null
      ) {
        Result = false;
        setValCodeTiendaMaster(false);
      } else {
        setValCodeTiendaMaster(true);
      }
    }

    if (Result) {
      Controller.save(
        router,
        codeTienda,
        codeTiendaMaster,
        codeCadenaMaster,
        horarioDesde,
        horarioHasta,
        lunes,
        martes,
        miercoles,
        jueves,
        viernes,
        sabado,
        domingo,
        nombreComercial,
        apellidoComercial,
        telefonoComercial,
        correoElectronicoComercial,
        dniComercial,
        observacion
      );
    } else {
      Controller.Mensaje(messageError);
    }
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Cadena Master</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        {mode == 0 ? (
                          <SelectSimple
                            value={codeCadenaMaster}
                            setValue={setCodeCadenaMaster}
                            valueError={ValCodeCadenaMaster}
                            setValueError={setValCodeCadenaMaster}
                            isSubmit={isSubmit}
                            lista={ListaComboCadenaMaster}
                            onChangeSection={onChangeCodeCadena}
                          >
                            --Seleccion--
                          </SelectSimple>
                        ) : (
                          <input
                            type="text"
                            className="form-control-text"
                            readOnly
                            value={nombreCadena}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>Tienda Master</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        {mode == 0 ? (
                          <SelectSimple
                            value={codeTiendaMaster}
                            setValue={setCodeTiendaMaster}
                            valueError={ValCodeTiendaMaster}
                            setValueError={setValCodeTiendaMaster}
                            isSubmit={isSubmit}
                            lista={ListaComboTiendaMaster}
                            onChangeSection={onChangeCodeMaster}
                          >
                            --Seleccion--
                          </SelectSimple>
                        ) : (
                          <input
                            type="text"
                            className="form-control-text"
                            readOnly
                            value={nombreTienda}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Codigo Tienda</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={codigoTienda}
                          setValue={setCodigoTienda}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label>Nombre tienda B2B</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreTiendaB2B}
                          setValue={setNombreTiendaB2B}
                          maxLenght={100}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>Direccion</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={direccion}
                          setValue={setDireccion}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label>Ubigeo</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={ubigeo}
                          setValue={setUbigeo}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-2">
                      <label>Longitud</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={longitud}
                          setValue={setLongitud}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label>Latitud</label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={latitud}
                          setValue={setLatitud}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row"></div>
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos Contacto</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Nombre</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreComercial}
                          setValue={setNombreComercial}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label>Apellido</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={apellidoComercial}
                          setValue={setApellidoComercial}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Telefono</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={telefonoComercial}
                          setValue={setTelefonicoComercial}
                          maxLenght={8}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label>Correo Electronico</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={correoElectronicoComercial}
                          messageError={messageError}
                          setValue={setCorreoElectronicoComercial}
                          maxLenght={128}
                          lowerCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>DNI</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={dniComercial}
                          setValue={setDniComercial}
                          maxLenght={8}
                          onlyNumbers={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h4>Datos Adicionales</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Horario Desde</Label>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group">
                        <Textbox
                          value={horarioDesde}
                          setValue={setHorarioDesde}
                          maxLenght={15}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label>Horario Hasta</Label>
                    </div>
                    <div className="col-lg-2">
                      <Textbox
                        value={horarioHasta}
                        setValue={setHorarioHasta}
                        maxLenght={15}
                        upperCase={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label>Observacion</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <TextArea
                          value={observacion}
                          setValue={setObservacion}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <label>
                        <input
                          type="checkbox"
                          checked={lunes}
                          onChange={(e) => {
                            setLunes(e.currentTarget.checked);
                          }}
                        />{" "}
                        Lunes
                      </label>
                    </div>
                    <div className="col-lg-2">
                      <label>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={martes}
                            onChange={(e) => {
                              setMartes(e.currentTarget.checked);
                            }}
                          />{" "}
                          Martes
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-2">
                      <label>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={miercoles}
                            onChange={(e) => {
                              setMiercoles(e.currentTarget.checked);
                            }}
                          />{" "}
                          Miercoles
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-2">
                      <label>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={jueves}
                            onChange={(e) => {
                              setJueves(e.currentTarget.checked);
                            }}
                          />{" "}
                          Jueves
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-2">
                      <label>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={viernes}
                            onChange={(e) => {
                              setViernes(e.currentTarget.checked);
                            }}
                          />{" "}
                          Viernes
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-2">
                      <label>
                        <div className="form-group">
                          <input
                            type="checkbox"
                            checked={sabado}
                            onChange={(e) => {
                              setSabado(e.currentTarget.checked);
                            }}
                          />{" "}
                          Sabado
                        </div>
                      </label>
                      <div className="form-group"></div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => ValidateCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
