"use client";

import React, { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import ContentHeader from "../layout/ContentHeader";
import DatePicker, { registerLocale } from "react-datepicker";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/registrometaventa-controller";
import * as Repository from "../../../repositories/registrometaventa-repository";
import SelectSimple from "../layout/SelectSimple";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want

export default function ConsultaRegistroMetaVenta(): React.JSX.Element {
  const router = useNavigate();

  const [listaComboUsuario, setListaComboUsuario] = React.useState([]);
  const [listaComboTienda, setListaComboTienda] = React.useState([]);
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  const [FilterCodeTienda, setFilterCodeTienda] = React.useState("");
  const [FilterCodeUsuario, setFilterCodeUsuario] = React.useState("");
  const [FilterPeriodo, setFilterPeriodo] = React.useState(new Date());
  React.useEffect(() => {
    Controller.doConsultar(
      setListaConsulta,
      FilterCodeTienda,
      FilterCodeUsuario,
      FilterPeriodo
    );
    Controller.getListaComboTienda(setListaComboTienda);
    Controller.getListaComboUsuario(setListaComboUsuario);
  }, []);
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Consulta Registro Meta Venta"}
        homePath={"Home"}
        currentPath={"Mantenimiento Registro Meta Venta"}
      ></ContentHeader>
      <ContentBody title={"Consulta Registro Meta Venta"}>
        <div className="row">
          <div className="col-lg-2">
            <label>Periodo</label>
          </div>
          <div className="col-lg-4 ">
            <div className="form-group">
              <DatePicker
                locale="es"
                className="form-control-text"
                popperPlacement="top-end"
                selected={FilterPeriodo}
                onChange={(date: any) => setFilterPeriodo(date)}
                dateFormat={"yyyy-MM"}
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            <label>Usuario</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={FilterCodeUsuario}
                setValue={setFilterCodeUsuario}
                lista={listaComboUsuario}
              >
                --TODOS--
              </SelectSimple>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            <label>Tienda</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <SelectSimple
                value={FilterCodeTienda}
                setValue={setFilterCodeTienda}
                lista={listaComboTienda}
              >
                --TODOS--
              </SelectSimple>
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-lg-6">
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={() => Controller.toNuevo(router)}
              >
                Nuevo
              </button>
              <button
                className="btn btn-info"
                onClick={() =>
                  Controller.doConsultar(
                    setListaConsulta,
                    FilterCodeTienda,
                    FilterCodeUsuario,
                    FilterPeriodo
                  )
                }
              >
                Consultar
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaterialReactTable
              columns={Repository.GetColumns()}
              data={ListaConsulta}
              enableRowActions
              renderRowActions={({ row, table }) => (
                <div style={{ width: 100 }}>
                  <div className="row">
                    <div className="col-lg-4">
                      <IconButton
                        onClick={() =>
                          Controller.toEditar(router, row.original)
                        }
                      >
                        <EditIcon titleAccess="Editar" />
                      </IconButton>
                    </div>
                    <div className="col-lg-4">
                      <IconButton
                        onClick={() =>
                          Controller.toQuestionInactivaActiva(
                            row.original,
                            setListaConsulta
                          )
                        }
                      >
                        <DeleteIcon titleAccess="Eliminar" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
