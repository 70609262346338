import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import "react-datepicker/dist/react-datepicker.css";
import * as Controller from "../../../controllers/checkinouttienda-controller";
import * as Repository from "../../../repositories/checkinouttienda-repository";
import es from "date-fns/locale/es"; // the locale you want
import DatePicker, { registerLocale } from "react-datepicker";
import SelectSimple from '../layout/SelectSimple';
registerLocale("es", es); // register it with the name you want

export default function ConsultaCheckInOutTienda(): React.JSX.Element {
    const router = useNavigate()
    const [listaComboPerfil, setListaComboPerfil] = React.useState([])
    const [FilterNombreCompleto, setFilterNombreCompleto] = React.useState('')
    const [ListaConsulta, setListaConsulta] = React.useState([])
    const [idPerfil, setIdPerfil] = React.useState('0')
    const [filterFechaDesde, setFilterFechaDesde] = React.useState(new Date)
    const [filterFechaHasta, setFilterFechaHasta] = React.useState(new Date)
    React.useEffect(() => {

        Controller.doConsultar(setListaConsulta, idPerfil, filterFechaDesde, filterFechaHasta, FilterNombreCompleto)
        Controller.getListaComboPerfil(setListaComboPerfil)
    }, [])
    return (<div className="content-wrapper">
        <ContentHeader title={"Consulta Entrada y Salida"} homePath={"Home"} currentPath={"Mantenimiento CheckInOutTienda"} >
        </ContentHeader>
        <ContentBody title={"Consulta Entrada y Salida"}>
            <div className='row'>
                <div className='col-lg-2 '>
                    <label>Perfil</label>
                </div>
                <div className='col-lg-4 '>
                    <div className='form-group'>
                        <SelectSimple
                            value={idPerfil}
                            setValue={setIdPerfil}
                            lista={listaComboPerfil} >
                            --TODOS--
                        </SelectSimple>
                    </div>
                </div>
                <div className='col-lg-2 '>
                    <label> Nombre</label>
                </div>
                <div className='col-lg-4 '>
                    <div className='form-group'>
                        <input type="text" className="form-control-text" value={FilterNombreCompleto}
                            onChange={e => { setFilterNombreCompleto(e.currentTarget.value); }} />
                    </div>
                </div>
            </div>
            <div className='row mt-1'>
                <div className='col-lg-2 '>
                    <label>Fecha Desde</label>
                </div>
                <div className='col-lg-4 '>
                    <div className='form-group'>
                        <DatePicker locale="es" className='form-control-text' popperPlacement='top-end' selected={filterFechaDesde} onChange={(date: any) => setFilterFechaDesde(date)} dateFormat={"dd/MM/yyyy"} />
                    </div>
                </div>
                <div className='col-lg-2 col'>
                    <label>Fecha Hasta</label>
                </div>
                <div className='col-lg-4 col'>
                    <div className='form-group'>
                        <DatePicker locale="es" className='form-control-text' popperPlacement='top-end' selected={filterFechaHasta} onChange={(date: any) => setFilterFechaHasta(date)} dateFormat={"dd/MM/yyyy"} />
                    </div>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-md-12'>
                    <div className='form-group'>

                        <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, idPerfil, filterFechaDesde, filterFechaHasta, FilterNombreCompleto)}>Consultar</button>
                        <button className='btn btn-success' onClick={() => Controller.doExportar(idPerfil, filterFechaDesde, filterFechaHasta, FilterNombreCompleto)}  >Exportar</button>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-12">
                    <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}

                        renderRowActions={({ row, table }) => (
                            <div>
                            </div>
                        )} />
                </div>
            </div>
        </ContentBody>
    </div>
    )
}
