'use client'

import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from "../../../controllers/tienda-controller";
import * as Repository from "../../../repositories/tienda-repository";
import SelectSimple from '../layout/SelectSimple';
import Textbox from '../layout/Textbox';
export default function ConsultaTienda() {
  const router = useNavigate();
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [ListaComboCadenaMaster, setListaComboCadenaMaster] = React.useState([])
  const [FilterCadena, setFilterCadena] = React.useState("")
  const [FilterCodigoTienda, setFilterCodigoTienda] = React.useState("")
  const [FilterNombreTienda, setFilterNombreTienda] = React.useState("")
  React.useEffect(() => {
    Controller.getListaComboCadenaMaster(setListaComboCadenaMaster)
    Controller.doConsultar(setListaConsulta, FilterCadena, FilterCodigoTienda, FilterNombreTienda)
  }, [])
  return (<div className="content-wrapper">
    <ContentHeader title={"Consulta Tienda"} homePath={"Home"} currentPath={"Mantenimiento Tienda"} >
    </ContentHeader>
    <ContentBody title={"Consulta Tienda"}>
      <div className='row'>
        <div className='col-lg-2'>
          <label>Cadena </label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <SelectSimple
              lista={ListaComboCadenaMaster}
              value={FilterCadena}
              setValue={setFilterCadena}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
        <div className='col-lg-2'>
          <label>Tienda</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <Textbox
              value={FilterNombreTienda}
              setValue={setFilterNombreTienda} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-2'>
          <label>Codigo Tienda</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <Textbox
              value={FilterCodigoTienda}
              setValue={setFilterCodigoTienda} />
          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-md-12'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterCadena, FilterCodigoTienda, FilterNombreTienda)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterCadena, FilterCodigoTienda, FilterNombreTienda)}  >Exportar</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 60 }}>
                <div className="row">
                  <div className="col-lg-6">
                    <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                      <EditIcon titleAccess="Editar" />
                    </IconButton>
                  </div>
                  <div className="col-lg-6">
                    <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                      <DeleteIcon titleAccess="Eliminar" />
                    </IconButton>
                  </div>
                </div>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}
