import React, { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";

import {
  Edit as EditIcon,
  PictureAsPdf as EditPdf,
  Delete as DeleteIcon,
  Email as EmailIcon,
  LockReset as LockResetIcon,
} from "@mui/icons-material";
import ContentHeader from "../layout/ContentHeader";
import DatePicker, { registerLocale } from "react-datepicker";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/generardocumentos-controller";
import * as Repository from "../../../repositories/generardocumentos-repository";
import "react-datepicker/dist/react-datepicker.css";
import Label from "../layout/Label";
import SelectSimple from "../layout/SelectSimple";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want
export default function ConsultaGenerarDocumento(): React.JSX.Element {
  const router = useNavigate();
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  const [ListaComboTienda, setListaComboTienda] = React.useState([]);
  const [ListaComboUsuario, setListaComboUsuario] = React.useState([]);
  const [FilterCodeTienda, setFilteCodeTienda] = React.useState("");
  const [FilterCodeUsuario, setFilteCodeUsuario] = React.useState("");
  const [FilterPeriodo, setFilterPeriodo] = React.useState(new Date());
  React.useEffect(() => {
    Controller.doConsultar(
      setListaConsulta,
      FilterCodeUsuario,
      FilterCodeTienda,
      FilterPeriodo
    );
    Controller.getListaComboTiendaConsulta(setListaComboTienda);
    Controller.getListaComboUsuarioConsulta(setListaComboUsuario);
  }, []);
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Consulta Generar Documento "}
        homePath={"Home"}
        currentPath={"Mantenimiento Generar Documento "}
      ></ContentHeader>
      <ContentBody title={"Consulta Generar Documento"}>
        <div className="card-body">
          <div className="row ">
            <div className="col-lg-2 ">
              <Label>Periodo</Label>
            </div>
            <div className="col-lg-4 ">
              <div className="form-group">
                <DatePicker
                  locale="es"
                  className="form-control-text"
                  popperPlacement="top-end"
                  selected={FilterPeriodo}
                  onChange={(date: any) => setFilterPeriodo(date)}
                  dateFormat={"MM-yyyy"}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 ">
              <Label>Usuario</Label>
            </div>
            <div className="col-lg-4 ">
              <div className="form-group">
                <SelectSimple
                  value={FilterCodeUsuario}
                  setValue={setFilteCodeUsuario}
                  lista={ListaComboUsuario}
                >
                  --Seleccion--
                </SelectSimple>
              </div>
            </div>
            <div className="col-lg-2 ">
              <Label>Tiendas</Label>
            </div>
            <div className="col-lg-4 ">
              <SelectSimple
                value={FilterCodeTienda}
                setValue={setFilteCodeTienda}
                lista={ListaComboTienda}
              >
                --Seleccion--
              </SelectSimple>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="form-group">
                <button
                  className="btn btn-primary"
                  onClick={() => Controller.toNuevo(router)}
                >
                  Nuevo
                </button>
                <button
                  className="btn btn-info"
                  onClick={() =>
                    Controller.doConsultar(
                      setListaConsulta,
                      FilterCodeUsuario,
                      FilterCodeTienda,
                      FilterPeriodo
                    )
                  }
                >
                  Consultar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <MaterialReactTable
              columns={Repository.GetColumns()}
              data={ListaConsulta}
              enableRowActions
              renderRowActions={({ row, table }) => (
                <div style={{ width: 100 }}>
                  <div className="row">
                    <div className="col-lg-4">
                      <div>
                        <IconButton
                          onClick={() =>
                            Controller.doDescargarDocumentoCombinado(
                              row.original
                            )
                          }
                        >
                          <EditPdf />
                        </IconButton>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <IconButton
                        onClick={() =>
                          Controller.toQuestionInactivaActiva(
                            row.original,
                            setListaConsulta,
                            () => {
                              Controller.doConsultar(
                                setListaConsulta,
                                FilterCodeUsuario,
                                FilterCodeTienda,
                                FilterPeriodo
                              );
                            }
                          )
                        }
                      >
                        <DeleteIcon titleAccess="Eliminar" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
