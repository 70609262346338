"use client";

import React, { useMemo, useContext } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  LockReset as LockResetIcon,
  RestartAlt as RestartAltIcon,
} from "@mui/icons-material";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/usuario-controller";
import * as Repository from "../../../repositories/usuario-repository";
import SelectSimple from "../layout/SelectSimple";
import { UserContext } from "../../main/main-screen";
import Textbox from "../layout/Textbox";
import { OPCIONES_MENU } from "../../../utils/contants";
import * as Validation from "../../../utils/validation";

export default function ConsultaUsuario(): React.JSX.Element {
  const router = useNavigate();
  const { Perfilusuario, OpcionesMenu } = useContext(UserContext);

  //const data = useContext(MyContext);
  const [FilterEmpresa, setFilterEmpresa] = React.useState("");
  const [FilterApeNom, setFilterApeNom] = React.useState("");
  const [FilterNombreUsuario, setFilterNombreUsuario] = React.useState("");
  const [ListaComboEmpresa, setListaComboEmpresa] = React.useState([]);
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  //const [Perfilusuario, setPerfilusuario] = React.useState(0);
  const [FileExcel, setFileExcel] = React.useState(null);
  const [soloActivos, setSoloActivos] = React.useState(true);

  React.useEffect(() => {

    if (!Validation.ValidateOpcionMenu(OPCIONES_MENU.OPC_USUARIO, OpcionesMenu)){
      router("/main");  
    }

    //Controller.getUsuarioInfo(setPerfilusuario);
    Controller.getListaComboEmpresa(setListaComboEmpresa);
    Controller.doConsultar(
      setListaConsulta,
      FilterEmpresa,
      FilterApeNom,
      FilterNombreUsuario,
      soloActivos
    );
  }, []);

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    setFileExcel(fileToUpload);
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Consulta Usuario"}
        homePath={"Home"}
        currentPath={"Mantenimiento Usuario"}
      ></ContentHeader>
      <ContentBody title={"Consulta Usuario"}>
        {Perfilusuario == 1 ? (
          <div className="row">
            <div className="col-lg-2">
              <label>Empresa </label>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <SelectSimple
                  lista={ListaComboEmpresa}
                  value={FilterEmpresa}
                  setValue={setFilterEmpresa}
                >
                  --TODOS--
                </SelectSimple>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          <div className="col-lg-2">
            <label>Nombres y Apellidos </label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <Textbox value={FilterApeNom} setValue={setFilterApeNom} />
            </div>
          </div>
          <div className="col-lg-2">
            <label>Nombre Usuario</label>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <Textbox
                value={FilterNombreUsuario}
                setValue={setFilterNombreUsuario}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">

          </div>
          <div className="col-lg-4">
            <label>
              <input
                type="checkbox"
                checked={soloActivos}
                onChange={(e) => {
                  setSoloActivos(e.currentTarget.checked);
                }}
              />{" "}
              Solo activos
            </label>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-lg-6">
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={() => Controller.toNuevo(router)}
              >
                Nuevo
              </button>
              <button
                className="btn btn-info"
                onClick={() =>
                  Controller.doConsultar(
                    setListaConsulta,
                    FilterEmpresa,
                    FilterApeNom,
                    FilterNombreUsuario,
                    soloActivos
                  )
                }
              >
                Consultar
              </button>
              <button
                className="btn btn-success"
                onClick={() =>
                  Controller.doExportar(
                    FilterEmpresa,
                    FilterApeNom,
                    FilterNombreUsuario,
                    soloActivos
                  )
                }
              >
                Exportar
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-8">
                <div className="mt-2">
                  <input
                    type="file"
                    id="fileExcel"
                    onChange={onChangeFileUpload}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <button
                  className="btn btn-primary"
                  onClick={() => Controller.importarUsuarios(router, FileExcel)}
                >
                  Importar
                </button>
              </div>
              <div className="col-lg-6">
                <button
                  className="btn btn-info"
                  onClick={() => Controller.doCargarPlantillaUsuario()}
                >
                  Descargar Plantilla
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaterialReactTable
              columns={Repository.GetColumns()}
              data={ListaConsulta}
              muiTableBodyRowProps={({ row }) => ({
                className: row.original.Estado === 0 ? 'celda_inactiva' : 'celda_activa'
              })}
              enableRowActions
              renderRowActions={({ row, table }) => (
                <div style={{ width: 100 }}>
                  <div className="row">
                    <div className="col-lg-4">
                      <IconButton
                        onClick={() =>
                          Controller.toEditar(router, row.original)
                        }
                      >
                        <EditIcon titleAccess="Editar" />
                      </IconButton>
                    </div>
                    <div className="col-lg-4">
                      <IconButton
                        onClick={() =>
                          Controller.toQuestionInactivaActiva(
                            row.original,
                            setListaConsulta
                          )
                        }
                      >
                        {row.original.Estado == 1 ?
                        <DeleteIcon titleAccess="Eliminar" />
                        :<RestartAltIcon titleAccess="Activar" />}
                      </IconButton>
                    </div>
                    <div className="col-lg-4">
                      <IconButton
                        onClick={() =>
                          Controller.toQuestionrReset(
                            row.original,
                            setListaConsulta
                          )
                        }
                      >
                        <LockResetIcon titleAccess="Resetear clave" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
