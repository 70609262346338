"use client";

import React, { useMemo } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import DatePicker, { registerLocale } from "react-datepicker";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/programacionusuario-controller";
import * as Repository from "../../../repositories/programacionusuario-repository";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // the locale you want
import SelectSimple from "../layout/SelectSimple";
import Select from "react-select";
registerLocale("es", es); // register it with the name you want
export default function ConsultaProgramacionUsuario(): React.JSX.Element {
  const router = useNavigate();
  const [ListaComboUsuarios, setListaComboUsuario] = React.useState([]);
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  const [FilterCodeEmpresa, setFilterCodeEmpresa] = React.useState("");
  const [FilterCodeUsuario, setFilterCodeUsuario] = React.useState([]);
  const [filterFechaDesde, setFilterFechaDesde] = React.useState(new Date());
  const [filterFechaHasta, setFilterFechaHasta] = React.useState(new Date());
  const [FilterPeriodo, setFilterPeriodo] = React.useState(new Date());
  const [FileExcel, setFileExcel] = React.useState(null);

  React.useEffect(() => {
    Controller.getListaComboUsuario(setListaComboUsuario);
    Controller.doConsultar(
      setListaConsulta,
      filterFechaDesde,
      filterFechaHasta,
      FilterCodeUsuario,
      FilterPeriodo
    );
  }, []);

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    setFileExcel(fileToUpload);
  }

  return (
    <div className="content-wrapper">
      <ContentHeader
        title={"Consulta Programacion Usurio"}
        homePath={"Home"}
        currentPath={"Mantenimiento Programacion Usuario"}
      ></ContentHeader>
      <ContentBody title={"Consulta Programacion Usuario"}>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-2 ">
              <label>Nombre Usuario</label>
            </div>
            <div className="col-lg-4 ">
              <Select
                value={FilterCodeUsuario}
                closeMenuOnSelect={false}
                options={ListaComboUsuarios}
                isClearable
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}            
                onChange={(e: any) => {
                  setFilterCodeUsuario(e);
                }}
              />

              {/* <SelectSimple
                value={FilterCodeUsuario}
                setValue={setFilterCodeUsuario}
                lista={ListaComboUsuarios}
              >
                --Selecionar--
              </SelectSimple> */}
            </div>
            <div className="col-lg-2 ">
              <label>Periodo</label>
            </div>
            <div className="col-lg-4 ">
              <div className="form-group">
                <DatePicker
                  locale="es"
                  className="form-control-text"
                  popperPlacement="top-end"
                  selected={FilterPeriodo}
                  onChange={(date: any) => setFilterPeriodo(date)}
                  dateFormat={"yyyy-MM"}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 ">
              <label>Fecha Desde</label>
            </div>
            <div className="col-lg-4 ">
              <div className="form-group">
                <DatePicker
                  locale="es"
                  className="form-control-text"
                  popperPlacement="top-end"
                  selected={filterFechaDesde}
                  onChange={(date: any) => setFilterFechaDesde(date)}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
            </div>
            <div className="col-lg-2 ">
              <label>Fecha Hasta</label>
            </div>
            <div className="col-lg-4 ">
              <div className="form-group">
                <DatePicker
                  locale="es"
                  className="form-control-text"
                  popperPlacement="top-end"
                  selected={filterFechaHasta}
                  onChange={(date: any) => setFilterFechaHasta(date)}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <button
                  className="btn btn-primary"
                  onClick={() => Controller.toNuevo(router)}
                >
                  Nuevo
                </button>
                <button
                  className="btn btn-info"
                  onClick={() =>
                    Controller.doConsultar(
                      setListaConsulta,
                      filterFechaDesde,
                      filterFechaHasta,
                      FilterCodeUsuario,
                      FilterPeriodo
                    )
                  }
                >
                  Consultar
                </button>
                <button
                  className="btn btn-success"
                  onClick={() =>
                    Controller.doExportar(
                      filterFechaDesde,
                      filterFechaHasta,
                      FilterCodeUsuario,
                      FilterPeriodo
                    )
                  }
                >
                  Exportar
                </button>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="row">
                <div className="col-lg-8">
                  <div className="mt-2">
                    <input
                      type="file"
                      id="fileExcel"
                      onChange={onChangeFileUpload}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      Controller.importarProgramacionUsuario(router, FileExcel)
                    }
                  >
                    Importar
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    className="btn btn-info"
                    onClick={() =>
                      Controller.doCargarPlantillaProgramacionUsuario()
                    }
                  >
                    Descargar Plantilla
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaterialReactTable
              columns={Repository.GetColumns()}
              data={ListaConsulta}
              enableRowActions
              renderRowActions={({ row, table }) => (
                <div>
                  {/* <IconButton
                    onClick={() => Controller.toEditar(router, row.original)}
                  >
                    <EditIcon titleAccess="Editar" />
                  </IconButton> */}
                  <IconButton
                    onClick={() =>
                      Controller.toQuestionInactivaActiva(
                        row.original,
                        setListaConsulta
                      )
                    }
                  >
                    <DeleteIcon titleAccess="Eliminar" />
                  </IconButton>
                </div>
              )}
            />
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
