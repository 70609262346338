import React, { useMemo } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import ContentHeader from '../layout/ContentHeader';
import ContentBody from '../layout/ContentBody';
import * as Controller from '../../../controllers/tiendamaster-controller';
import * as Repository from '../../../repositories/tiendamaster-repository';
import SelectSimple from '../layout/SelectSimple';
import Textbox from '../layout/Textbox';
export default function Consulta() {
  const router = useNavigate()
  const [ListaConsulta, setListaConsulta] = React.useState([])
  const [ListaComboCadenaMaster, setListaComboCadenaMaster] = React.useState([])
  const [FilterCadenaMaster, setFilterCadenaMaster] = React.useState("")
  const [FilterCodigoTienda, setFilterCodigoTienda] = React.useState("")
  const [FilterNombreTiendaMaster, setFilterNombreTiendaMaster] = React.useState("")
  const [FileExcel, setFileExcel] = React.useState(null);
  React.useEffect(() => {
    Controller.getListaComboCadenaMaster(setListaComboCadenaMaster)
    Controller.doConsultar(setListaConsulta, FilterCadenaMaster, FilterCodigoTienda, FilterNombreTiendaMaster)
  }, []);
  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    setFileExcel(fileToUpload);
  }

  return (<div className="content-wrapper">
    <ContentHeader title={"Catalogo Tienda Master"} homePath={"Home"} currentPath={"Mantenimiento Tienda Master"} >
    </ContentHeader>
    <ContentBody title={"Catalogo Tienda Master"}>
      <div className='row'>
        <div className='col-lg-2'>
          <label>Cadena</label>
        </div>
        <div className='col-lg-4'>
          <div className='form-group'>
            <SelectSimple
              lista={ListaComboCadenaMaster}
              value={FilterCadenaMaster}
              setValue={setFilterCadenaMaster}>
              --TODOS--
            </SelectSimple>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-2 '>
          <label>Tienda</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <Textbox
              value={FilterNombreTiendaMaster}
              setValue={setFilterNombreTiendaMaster} />
          </div>
        </div>
        <div className='col-lg-2 '>
          <label>Codigo Tienda</label>
        </div>
        <div className='col-lg-4 '>
          <div className='form-group'>
            <Textbox
              value={FilterCodigoTienda}
              setValue={setFilterCodigoTienda} />
          </div>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-md-6'>
          <div className='form-group'>
            <button className='btn btn-primary' onClick={() => Controller.toNuevo(router)}>Nuevo</button>
            <button className='btn btn-info' onClick={() => Controller.doConsultar(setListaConsulta, FilterCadenaMaster, FilterCodigoTienda, FilterNombreTiendaMaster)}>Consultar</button>
            <button className='btn btn-success' onClick={() => Controller.doExportar(FilterCadenaMaster, FilterCodigoTienda, FilterNombreTiendaMaster)}  >Exportar</button>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-8">
              <div className="mt-2">
                <input
                  type="file"
                  id="fileExcel"
                  onChange={onChangeFileUpload}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <button
                className="btn btn-primary"
                onClick={() => Controller.importarTiendas(router, FileExcel)}
              >
                Importar
              </button>
            </div>

            <div className='col-lg -4'>
            <button className='btn btn-info' onClick={() => Controller.doDescargarPlantilla()}  >Descargar Plantilla</button>
            </div>

          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <MaterialReactTable columns={Repository.GetColumns()} data={ListaConsulta}
            enableRowActions
            renderRowActions={({ row, table }) => (
              <div style={{ width: 100 }}>
              <div className='row'>
                <div className="col-lg-4">
                <IconButton onClick={() => Controller.toEditar(router, row.original)}>
                  <EditIcon titleAccess="Editar" />
                </IconButton>
                </div>
                <div className="col-lg-4">
                <IconButton onClick={() => Controller.toQuestionInactivaActiva(row.original, setListaConsulta)}>
                  <DeleteIcon titleAccess="Eliminar" />
                </IconButton>
                </div>
              </div>
              </div>
            )} />
        </div>
      </div>
    </ContentBody>
  </div>
  )
}
