"use client";

import ContentHeader from "../layout/ContentHeader";
import { useParams, useNavigate } from "react-router-dom";
import * as Controller from "../../../controllers/producto-controller";
import React from "react";
import SelectSimple from "../layout/SelectSimple";
import Textbox from "../layout/Textbox";
import MaterialReactTable from "material-react-table";
import * as Repository from "../../../repositories/producto-repository";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Label from "../layout/Label";

export default function NuevoProducto() {
  const [ListaComboCategoria, setListaComboCategoria] = React.useState([]);
  const [ListaComboMarca, setListaComboMarca] = React.useState([]);
  const [ListaComboSubCategoria, setListaComboSubCategoria] = React.useState(
    []
  );
  const [ListaComboTienda, setListaComboTienda] = React.useState([]);
  const { code } = useParams();
  const router = useNavigate();
  const [ListaTiendas, setListaTiendas] = React.useState([]);
  const [codeProducto, setCodeProducto] = React.useState("");
  const [nombreProducto, setNombreProducto] = React.useState("");
  const [codeEmpresa, setCodeEmpresa] = React.useState("");
  const [codeMarca, setCodeMarca] = React.useState("");
  const [codeCategoria, setCodeCategoria] = React.useState("");
  const [codeSubCategoria, setCodeSubCategoria] = React.useState("");
  const [skuMaestro, setSkuMaestro] = React.useState("");
  const [skuCadena, setSkuCadena] = React.useState("");
  const [ean, setEan] = React.useState("");
  const [gramajeTamanio, setGramajeTamanio] = React.useState("");
  const [pvp, setPvp] = React.useState("");
  const [tiendas, setTiendas] = React.useState("");
  const [mode, setMode] = React.useState(0);
  const [tituloVentana, setTituloVentana] = React.useState("Nuevo Producto");
  const [valNombreProducto, setValNombreProducto] = React.useState(false);
  const [valCodeMarca, setValCodeMarca] = React.useState(false);
  const [valCodeCategoria, setValCodeCategoria] = React.useState(false);
  const [valCodeSubCategoria, setValCodeSubCategoria] = React.useState(false);
  const [valtiendas, setValTiendas] = React.useState(false);
  const [valSkuMaestro, setValSkuMaestro] = React.useState(false);
  const [valSkuCadena, setValSkuCadena] = React.useState(false);
  const [valEan, setValEan] = React.useState(false);
  const [valGramajeTamanio, setValGramajeTamanio] = React.useState(false);
  const [valPvp, setValPvp] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [urlImagen, setUrlImagen] = React.useState("");
  const onChangeCodeMarca = (e: any) => {
    setCodeMarca(e.target.value);
    if (
      e.target.value != null &&
      e.target.value != "" &&
      e.target.value != "0"
    ) {
      Controller.getListaComboCategoria(e.target.value, setListaComboCategoria);
    } else {
      setListaComboCategoria([]);
    }
    setListaComboSubCategoria([]);
  };

  const onChangeCodeMasterCategoria = (e: any) => {
    setCodeSubCategoria(e.target.value);
  };
  const onChangeCodeCategoria = (e: any) => {
    setCodeCategoria(e.target.value);
    if (
      e.target.value != null &&
      e.target.value != "" &&
      e.target.value != "0"
    ) {
      Controller.getListaComboSubCategoria(
        e.target.value,
        setListaComboSubCategoria
      );
    } else {
      setListaComboSubCategoria([]);
    }
  };
  React.useEffect(() => {
    Controller.getListaComboMarca(setListaComboMarca);
    Controller.getListaComboTienda(setListaComboTienda);
    if (code != "nuevo") {
      setTituloVentana("Editar Producto");
      setCodeProducto(code!);
      setMode(1);
      Controller.getByCode(
        code,
        setCodeCategoria,
        setListaComboCategoria,
        setCodeSubCategoria,
        setListaComboSubCategoria,
        setCodeMarca,
        setNombreProducto,
        setSkuMaestro,
        setSkuCadena,
        setEan,
        setGramajeTamanio,
        setPvp,
        setUrlImagen
      );
      Controller.getListaProductoTiendaDetalleByCodeProducto(
        code,
        setListaTiendas
      );
      setValEan(true);
    }
  }, []);
  function validarCampos() {
    var Result = true;
    setIsSubmit(true);
    if (nombreProducto == "" || nombreProducto == null) {
      Result = false;
      setValNombreProducto(false);
    } else {
      setValNombreProducto(true);
    }
    if (skuMaestro == "" || skuMaestro == null) {
      Result = false;
      setValSkuMaestro(false);
    } else {
      setValSkuMaestro(true);
    }
    if (skuCadena == "" || skuCadena == null) {
      Result = false;
      setValSkuCadena(false);
    } else {
      setValSkuCadena(true);
    }
    if (ean == "" || ean == null) {
      Result = false;
      setValEan(false);
    } else {
      setValEan(true);
    }
    if (gramajeTamanio == "" || gramajeTamanio == null) {
      Result = false;
      setValGramajeTamanio(false);
    } else {
      setValGramajeTamanio(true);
    }

    if (pvp == "" || pvp == null) {
      Result = false;
      setValPvp(false);
    } else {
      setValPvp(true);
    }
    if (codeCategoria == "" || codeCategoria == "0" || codeCategoria == null) {
      Result = false;
      setValCodeCategoria(false);
    } else {
      setValCodeCategoria(true);
    }

    if (codeMarca == "" || codeMarca == "0" || codeMarca == null) {
      Result = false;
      setValCodeMarca(false);
    } else {
      setValCodeMarca(true);
    }

    if (
      codeSubCategoria == "" ||
      codeSubCategoria == "0" ||
      codeSubCategoria == null
    ) {
      Result = false;
      setValCodeSubCategoria(false);
    } else {
      setValCodeSubCategoria(true);
    }
    if (Result) {
      Controller.save(
        router,
        codeProducto,
        codeMarca,
        codeCategoria,
        codeEmpresa,
        codeSubCategoria,
        nombreProducto,
        skuMaestro,
        skuCadena,
        ean,
        gramajeTamanio,
        pvp,
        file,
        urlImagen
      );
    } else {
      Controller.Mensaje();
    }
  }

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    //const reader = new FileReader();
    //var url = reader.readAsDataURL(file);
    setFile(fileToUpload);
  }
  return (
    <div className="content-wrapper">
      <ContentHeader
        title={tituloVentana}
        homePath={"Home"}
        currentPath={tituloVentana}
      ></ContentHeader>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <div className="d-flex flex-row gap-2">
                    <div className="align-items-center">
                      <i
                        className="bx bx-arrow-back arrow-back"
                        onClick={() => Controller.toConsulta(router)}
                      ></i>
                    </div>
                    <div className="">
                      <h4 className="card-title">{tituloVentana}</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <h4>Datos</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Marca</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeMarca}
                          setValue={setCodeMarca}
                          valueError={valCodeMarca}
                          setValueError={setValCodeMarca}
                          isSubmit={isSubmit}
                          lista={ListaComboMarca}
                          onChangeSection={onChangeCodeMarca}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Categoria</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeCategoria}
                          setValue={setCodeCategoria}
                          valueError={valCodeCategoria}
                          setValueError={setValCodeCategoria}
                          isSubmit={isSubmit}
                          lista={ListaComboCategoria}
                          onChangeSection={onChangeCodeCategoria}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Sub Categoria</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <SelectSimple
                          value={codeSubCategoria}
                          setValue={setCodeSubCategoria}
                          valueError={valCodeSubCategoria}
                          setValueError={setValCodeSubCategoria}
                          isSubmit={isSubmit}
                          lista={ListaComboSubCategoria}
                          onChangeSection={onChangeCodeMasterCategoria}
                        >
                          --Seleccion--
                        </SelectSimple>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Nombre Producto</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={nombreProducto}
                          valueError={valNombreProducto}
                          setValue={setNombreProducto}
                          setValueError={setValNombreProducto}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>EAN</Label>
                    </div>
                    <div className="col-lg-4">
                      <Textbox
                        value={ean}
                        valueError={valEan}
                        setValue={setEan}
                        setValueError={setValEan}
                        isSubmit={isSubmit}
                        upperCase={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>SKU Cadena</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={skuCadena}
                          valueError={valSkuCadena}
                          setValue={setSkuCadena}
                          setValueError={setValSkuCadena}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>SKU Maestro</Label>
                    </div>
                    <div className="col-lg-4">
                      <Textbox
                        value={skuMaestro}
                        valueError={valSkuMaestro}
                        setValue={setSkuMaestro}
                        setValueError={setValSkuMaestro}
                        isSubmit={isSubmit}
                        upperCase={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Gramaje</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={gramajeTamanio}
                          valueError={valGramajeTamanio}
                          setValue={setGramajeTamanio}
                          setValueError={setValGramajeTamanio}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <Label required={true}>PVP</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <Textbox
                          value={pvp}
                          valueError={valPvp}
                          setValue={setPvp}
                          setValueError={setValPvp}
                          isSubmit={isSubmit}
                          upperCase={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-2">
                      <Label>Imagen</Label>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          id="image"
                          accept="image/*"
                          type="file"
                          name="image"
                          onChange={onChangeFileUpload}
                        />
                      </div>
                    </div>
                    {urlImagen != null && urlImagen != "" ? (
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-4">
                            <Label>Vista Previa</Label>
                          </div>
                          <div className="col-lg-8">
                            <img src={urlImagen} style={{ width: 200 }} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <button
                          className="btn btn-default"
                          onClick={() => Controller.toConsulta(router)}
                        >
                          Cancelar
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => validarCampos()}
                        >
                          Guardar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-6">
                      <h4>Tiendas</h4>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <Label required={true}>Tiendas</Label>
                    </div>
                    <div className="col-lg-4">
                      <SelectSimple
                        value={tiendas}
                        setValue={setTiendas}
                        isSubmit={isSubmit}
                        lista={ListaComboTienda}
                      >
                        --Seleccion--
                      </SelectSimple>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          Controller.addMember(
                            tiendas,
                            ListaComboTienda,
                            ListaTiendas,
                            setListaTiendas
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <MaterialReactTable
                        columns={Repository.GetTColumns()}
                        data={ListaTiendas}
                        enablePagination={false}
                        enableRowSelection
                        enableTopToolbar={false}
                        renderRowActions={({ row, table }) => (
                          <div>
                            <IconButton
                              onClick={() =>
                                Controller.toQuestionEliminarTiendas(
                                  row.original,
                                  ListaTiendas,
                                  setListaTiendas
                                )
                              }
                            >
                              <DeleteIcon titleAccess="Eliminar" />
                            </IconButton>
                          </div>
                        )}
                      />
                      <div className="row mt-5">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <button
                              className="btn btn-default"
                              onClick={() => Controller.toConsulta(router)}
                            >
                              Cancelar
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                Controller.saveProductoTienda(
                                  router,
                                  codeProducto,
                                  ListaTiendas
                                )
                              }
                            >
                              Guardar Tiendas
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
